import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { RxHamburgerMenu } from "react-icons/rx";

export default function MenuBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navLinks = [
    // logo
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Events",
      link: "/events",
    },
    {
      name: "Flagship",
      link: "aboutus",
    },
    {
      name: "Team",
      link: "/team",
    },
    {
      name: "About Us",
      link: "/aboutus",
    },
    {
      name: "Contact",
      link: "contact",
    },

    // login
  ];

  return (
    <div className="lg:hidden">
      <RxHamburgerMenu onClick={handleClick} size={40} />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {navLinks.map((item) => (
          <MenuItem onClick={handleClose}>
            <a className="no-underline text-xl m-0 px-4 py-2" href={item.link}>
              {item.name}
            </a>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
