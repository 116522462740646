import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import helpdeskimage from "../Assets/user.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import EventsBox from "../components/EventsBox";
import { TbLayoutSidebarRightCollapseFilled, TbLayoutSidebarLeftCollapseFilled } from "react-icons/tb"
function LEEDevents() {
  let location = useLocation();
  const navigate = useNavigate();
  const verifiedLEEDevents = useSelector((state) => state.LEEDevents.value);
  const [verifiedevents, setVerifiedEvents] = useState([...verifiedLEEDevents]);
  // const [filteredevents, setfilteredevents] = React.useState([])
  const [renderevents, setrenderevents] = React.useState("All Events");
  const [showHelp, setShowHelp] = React.useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const events = [
    {
      title: "Idea Factory",
      desc: "Talk Session on  Design Thinking",
      img: "/idea-factory.jpeg",
    },
    {
      title: "Business Model Canvas Insights ",
      desc: "Gain valuable insights into business strategy at the Business Canvas Insights Session. An illuminating talk which will delve into the nuances of the Business Model Canvas, providing practical insights and real-world applications.",
      img: "/business-model.jpeg",
    },
    {
      title: "Entrepreneural Challenge",
      desc: "Engage in the Entrepreneural Challenge, featuring diverse rounds such as a business trivia quiz, logo identification, an exploration of entrepreneurial legacies, and a creative Business Pictionary, testing your knowledge and innovation in the dynamic world of entrepreneurship.",
      img: "/engage.jpeg",
    },
  ];

  const s = new Date();

  useEffect(() => {
    setVerifiedEvents([...verifiedLEEDevents]);
  }, [verifiedLEEDevents]);
  const choosepicker = (msg) => {
    setrenderevents(msg);
  };
  const helpdeskfunc = (msg) => {
    setShowHelp(msg);
  };

  useEffect(() => {
    // // console.log(renderevents);
    // // console.log(verifiedevents);
    if (renderevents == "All Events") {
      const allEvents = [...verifiedLEEDevents].sort(
        (a, b) => new Date(b.startdate) - new Date(a.startdate)
      );
      // // console.log(verifiedLEEDevents)
      setVerifiedEvents(allEvents);
    } else if (renderevents == "Today Events") {
      const todayEvents = verifiedLEEDevents.filter((event) => {
        const eventDate = new Date(event.startdate);
        const today = new Date();
        return eventDate.toDateString() == today.toDateString();
      });
      setVerifiedEvents(todayEvents);
    } else if (renderevents == "Upcoming Events") {
      const futureEvents = verifiedLEEDevents.filter((event) => {
        const eventDate = new Date(event.startdate);
        const today = new Date();
        return eventDate > today;
      });
      setVerifiedEvents(futureEvents);
    } else if (renderevents == "Completed Events") {
      const completedEvents = verifiedLEEDevents.filter((event) => {
        const eventDate = new Date(event.startdate);
        const today = new Date();
        return eventDate < today;
      });
      setVerifiedEvents(completedEvents);
    }
  }, [renderevents]);


  const [ActiveMenu, setActiveMenu] = useState(false);
  return (
    <div style={{ width: "100vw", fontFamily: "Nunito" }}>
      <Modal
        style={{ textAlign: "center", fontFamily: "Inter" }}
        show={showHelp}
        onHide={() => setShowHelp(false)}
        backdrop="static"
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-primary">
            <img src={helpdeskimage} style={{ width: 20, height: 20 }} alt="" />
            <b>&nbsp;LEED HELPDESK </b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>EVENTS ARE REGULARLY UPDATED</h6>
          <div className="d-flex align-items-end">
            <>CONTACT - </>{" "}
            <a onClick={() => navigate("/contact")} href="#">
              LEED HELP
            </a>
          </div>
        </Modal.Body>
      </Modal>
      <div className="w-screen z-1 lg:flex-row flex fixed py-10 lg:pt-10 pt-20 h-screen ">
        {/* <SidebarEvent choosepicker={choosepicker} helpdesk={helpdeskfunc} /> */}
        <button
          onClick={() => {
            setIsHidden(true);
          }}
          className="lg:hidden block absolute"
        >
          <TbLayoutSidebarRightCollapseFilled color="#3a83f4" size={50} />
        </button>
        {/* sidebar */}

        <div
          className={`w-3/4 z-1 min-h-screen fixed lg:hidden block top-0 bg-gradient-to-l from-blue-500 to-teal-500 ${isHidden == false ? '-left-full' : "left-0"}`}
        >
          <button
            onClick={() => {
              setIsHidden((prev) => !prev);
            }}
            className="text-4xl lg:hidden block pt-20"
          >
            <TbLayoutSidebarLeftCollapseFilled color="white" size={50} />
          </button>
          <div className="lg:pl-96 pl-20 justify-evenly">
            <NavLink
              className={` no-underline ${ActiveMenu == 'allevents'
                ? 'text-warning'
                : 'text-light'
                }`}
              onClick={(e) => {
                choosepicker('All Events');
                setActiveMenu('allevents');
              }}
            >
              <p>All Events</p>
            </NavLink>
            <NavLink
              className={` no-underline ${ActiveMenu == 'todayevents'
                ? 'text-warning'
                : 'text-light'
                }`}
              onClick={() => {
                choosepicker('Today Events');
                setActiveMenu('todayevents');
              }}
            >
              <p>Today Events</p>
            </NavLink>
            <NavLink
              className={` no-underline ${ActiveMenu == 'futureevents'
                ? 'text-warning'
                : 'text-light'
                }`}
              onClick={() => {
                choosepicker('Upcoming Events');
                setActiveMenu('futureevents');
              }}
            >
              <p>Future Events</p>
            </NavLink>
            <NavLink
              className={`no-underline ${ActiveMenu == 'overevents'
                ? 'text-warning'
                : 'text-light'
                }`}
              onClick={() => {
                choosepicker('Completed Events');
                setActiveMenu('overevents');
              }}
            >
              <p>
                Completed Events
              </p>
            </NavLink>
          </div>
        </div>
        <div
          className={`w-1/5 z-1 min-h-screen relative justify-evenly lg:block hidden bg-gradient-to-l from-blue-500 to-teal-500`}
        >
          <button
            onClick={() => {
              setIsHidden((prev) => !prev);
            }}
            className="text-4xl lg:hidden block pt-20"
          >
            <TbLayoutSidebarLeftCollapseFilled color="white" size={50} />
          </button>
          <div className="pt-80 pl-20">
            <NavLink
              className={` no-underline ${ActiveMenu == 'allevents'
                ? 'text-warning'
                : 'text-light'
                }`}
              onClick={(e) => {
                choosepicker('All Events');
                setActiveMenu('allevents');
              }}
            >
              <p>All Events</p>
            </NavLink>
            <NavLink
              className={` no-underline ${ActiveMenu == 'todayevents'
                ? 'text-warning'
                : 'text-light'
                }`}
              onClick={() => {
                choosepicker('Today Events');
                setActiveMenu('todayevents');
              }}
            >
              <p>Today Events</p>
            </NavLink>
            <NavLink
              className={` no-underline ${ActiveMenu == 'futureevents'
                ? 'text-warning'
                : 'text-light'
                }`}
              onClick={() => {
                choosepicker('Upcoming Events');
                setActiveMenu('futureevents');
              }}
            >
              <p>Future Events</p>
            </NavLink>
            <NavLink
              className={`no-underline ${ActiveMenu == 'overevents'
                ? 'text-warning'
                : 'text-light'
                }`}
              onClick={() => {
                choosepicker('Completed Events');
                setActiveMenu('overevents');
              }}
            >
              <p>
                Completed Events
              </p>
            </NavLink>
          </div>
        </div>
        <EventsBox categoryTitle={renderevents} categoryTitleEvents={renderevents == 'Completed Events' ||  renderevents == 'All Events'  ? [...verifiedLEEDevents, ...events] : []}
        />
      </div>
    </div>
  );
}

export default LEEDevents;
