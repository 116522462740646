import React from 'react';
import axios from 'axios';
import './login.css';
import LoadingScreen from 'react-loading-screen'
import { app } from '../Models/firebase';
import { OAuthProvider, getAuth, GoogleAuthProvider, signInWithPopup, signOut, deleteUser } from 'firebase/auth';
import googlepic from "../Assets/google.png"
import microsoftpic from "../Assets/microsoft.png"
import signoutpic from "../Assets/logout.png"
import { Link, useNavigate } from 'react-router-dom';
import logo from '../Assets/logo.png'
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { setlogging, setlogout } from '../Controllers/redux';
import loginImage from '../Assets/loginuserimg.png'
import trophyImage from '../Assets/trophy.png'
import 'react-toastify/dist/ReactToastify.css'
var cryptojs = require("crypto-js")
function Login() {
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const url = useSelector((state) => state.url.value)
  const [ignored, forceUpdate] = React.useReducer(x => x + 1, 0)
  const [blockedusers, setblockedusers] = React.useState([])
  const [loading, setloading] = React.useState(false)
  const [loadinglogoff, setloadinglogoff] = React.useState(false)

  const fetchData = async () => {
    await axios({
      method: "GET",
      url: `${url}/getverifiedblockedusers`,

    })
      .then(async (res) => {
        // // console.log(res)
        let resdocs = await JSON.parse(cryptojs.AES.decrypt(res.data.docs, 'kowshik123').toString(cryptojs.enc.Utf8))
        // // console.log(resdocs)
        resdocs && resdocs?.map((value, index) => {
          setblockedusers(prev => prev.concat(value['BlockedUsers']))
        })


      }).catch((err) => {
        // // console.log(err)
      })
  }
  React.useEffect(() => {
    fetchData();
  }, [])

  const auth = getAuth(app)
  const provider = new GoogleAuthProvider()
  provider.setCustomParameters({
    prompt: 'select_account'
  })
  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        if (!blockedusers.includes(result.user.email)) {
          let name = cryptojs.AES.encrypt(result.user.displayName, 'kowshik123').toString()
          sessionStorage.setItem('name', name)
          let email = cryptojs.AES.encrypt(result.user.email, 'kowshik123').toString()
          sessionStorage.setItem('email', email);
          let profilePic = cryptojs.AES.encrypt(result.user.photoURL, 'kowshik123').toString()
          sessionStorage.setItem('pic', JSON.stringify(profilePic))

          axios.post(`${url}/createuser`, {
            name: result.user.displayName,
            email: result.user.email,
            pic: result.user.photoURL,
          }).then((res) => {
            let userid = cryptojs.AES.encrypt(res.data.id, 'kowshik123').toString()
            sessionStorage.setItem('userid', JSON.stringify(userid))
          })

          navigate('/')
        }
        else if (blockedusers.includes(result.user.email)) {
          toast.error('Your account is Blocked !', {
            autoClose: 5000,
            position: toast.POSITION.BOTTOM_CENTER
          })
        }
      })
      .catch((error) => {
        toast.error(error.message.split(/[()]/)[1]?.replace('auth/', ''), {
          autoClose: 5000,
          position: toast.POSITION.BOTTOM_CENTER
        })
      })
  }
  const microsoftProvider = new OAuthProvider('microsoft.com');
  microsoftProvider.setCustomParameters({
    // Optional "tenant" parameter in case you are using an Azure AD tenant.
    // eg. '8eaef023-2b34-4da1-9baa-8bc8c9d6a490' or 'contoso.onmicrosoft.com'
    // or "common" for tenant-independent tokens.
    // The default value is "common".
    tenant: '6b8b8296-bdff-4ad8-93ad-84bcbf3842f5',
    prompt: 'select_account'
  });
  const signInWithMicrosoft = () => {
    signInWithPopup(auth, microsoftProvider)
      .then((result) => {
        if (!blockedusers.includes(result.user.email)) {
          const credential = OAuthProvider.credentialFromResult(result);

          const accessToken = credential.accessToken;
          const idToken = credential.idToken;

          let name = cryptojs.AES.encrypt(result.user.displayName, 'kowshik123').toString()
          sessionStorage.setItem('name', name)
          let email = cryptojs.AES.encrypt(result.user.email, 'kowshik123').toString()
          sessionStorage.setItem('email', email);
          const lookupMsAzureProfilePhoto = (accessToken) => {
            //  setloading(true)
            //  sessionStorage.setItem('islogging',true)
            dispatch(setlogging(true))
            fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'image/jpg'
              }
            })
              .then(function (response) {

                return response.blob();
              })
              .then(function (blob) {

                const reader = new FileReader()
                reader.readAsDataURL(blob)
                reader.onload = async () => {
                  const base64data = reader.result;
                  let profilePic = cryptojs.AES.encrypt(base64data, 'kowshik123').toString()
                  sessionStorage.setItem('pic', JSON.stringify(profilePic))

                  await axios.post(`${url}/createuser`, {
                    name: result.user.displayName,
                    email: result.user.email,
                    pic: base64data,
                  }).then((res) => {
                    let userid = cryptojs.AES.encrypt(res.data.id, 'kowshik123').toString()
                    sessionStorage.setItem('userid', JSON.stringify(userid))
                  })
                  // setloading(false)

                  navigate('/')
                }
              })
              .catch((e) =>  console.log('error injecting photo'));
          };
          lookupMsAzureProfilePhoto(accessToken)
        }
        else if (blockedusers.includes(result.user.email)) {
          toast.error('Your account is Blocked !', {
            autoClose: 5000,
            position: toast.POSITION.BOTTOM_CENTER
          })
        }
      })
      .catch((error) => {
        // Handle error.
        toast.error(error.message.split(/[()]/)[1]?.replace('auth/', ''), {
          autoClose: 5000,
          position: toast.POSITION.BOTTOM_CENTER
        })
      });

  }
  const SignOutMember = () => {
    dispatch(setlogout(true));
    deleteUser(auth.currentUser)
      .then(() => {
        // Sign-out successful.
        alert("sign out success")
        sessionStorage.removeItem('name');
        sessionStorage.removeItem('email');
        sessionStorage.removeItem('pic');
        sessionStorage.removeItem('userid');
        sessionStorage.removeItem('verifyuic');
        dispatch(setlogout(false));
        navigate('/');
      })
      .catch((error) => {
        sessionStorage.removeItem('name');
        sessionStorage.removeItem('email');
        sessionStorage.removeItem('pic');
        sessionStorage.removeItem('userid');
        sessionStorage.removeItem('verifyuic');
        dispatch(setlogout(false));
        navigate("/")
        // toast.error(
        //   error.message.split(/[()]/)[1]?.replace('auth/', ''),
        //   {
        //     autoClose: 5000,
        //     position: toast.POSITION.BOTTOM_CENTER
        //   }
        // );
      });
  };

  return (
    <div className="h-screen flex flex-col items-center w-screen justify-center">
      <ToastContainer />
      <div className="shadow-2xl md:w-96 w-3/4  py-4 flex flex-col items-center justify-evenly rounded-3xl" >
        <img style={{ width: 200, height: 100, objectFit: 'contain' }} src={logo} alt="" />
        <div className="flex flex-col items-center justify-evenly w-full" >
          <h4 className='text-blue-500' >Login / Sign-Up</h4>
          <div className='text-white bg-yellow-400 flex items-center justify-center px-2 py-1 rounded-xl'>
            <p className='m-0 text-xs'> Enable Popups in settings to login</p>
          </div>
        </div>
        <div className='mt-5 w-full flex flex-col items-center justify-center'>
          {/* {(sessionStorage.getItem('name') == null || sessionStorage.getItem('name') == undefined) && <button className='button shadow-xl my-2' onClick={signInWithGoogle}> <span><img src={googlepic} alt="" /></span> <p className='bg-primary text-light'>Sign In With Google</p></button>} */}
          {(sessionStorage.getItem('name') == null || sessionStorage.getItem('name') == undefined) && <button className='button shadow-xl my-2' onClick={signInWithMicrosoft}><span><img src={microsoftpic} alt="" /></span> <p className='bg-primary text-light'>Sign In With Microsoft</p></button>} <br />
          {(sessionStorage.getItem('name') !== null && sessionStorage.getItem('name') !== undefined) && <button className='button shadow-xl my-2' onClick={SignOutMember}><span><img style={{ height: 30, width: 30 }} src={signoutpic} alt="" /></span> <p className='bg-primary text-light'>Sign Out</p></button>} <br />

        </div>
        {/* <div className='StartJourney text-light'>
          <h4>Kick Start Your <br /> Startup Journey !
            <img src={trophyImage} style={{ height: 20, width: 20, marginBottom: 5 }} alt="" />
          </h4>
          <div className='text-muted copyrights_footer' style={{ fontSize: 12 }} >2023 &#169; KCT LEED . All Right Reserved</div>
        </div> */}

      </div>

    </div>
  );
}

export default Login;
