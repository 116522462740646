import React, { useState } from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import { BsFillCheckCircleFill } from "react-icons/bs";
import "pure-react-carousel/dist/react-carousel.es.css";
import "./home.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { setlogging, setlogout } from "../Controllers/redux";
import { useSelector, useDispatch } from "react-redux";
import ImageHolderHome from "../Models/imageHolderHome";
import Footer from "../components/footer";
import axios from "axios";
import Hero from "../components/Hero";
import PichtesCarousel from "../components/PichtesCarousel";
import EventCarousel from "../components/EventCarousel";
import StudentEngageMent from "../components/StudentEngageMent";
import goal from "../Assets/business-goal.gif";
import team from "../Assets/sales-team.png";
import winning from "../Assets/winning.png";
import Processflow from "../components/ProcessFlow";
import Divider from "../components/Divider";

function Home() {
  const dispatch = useDispatch();
  const url = useSelector((state) => state.url.value);
  const experts = useSelector((state) => state.experts.value);
  const pitches = useSelector((state) => state.pitch.value);
  const newGoals = [
    "Regular expert talks: Insights from industry leaders.",
    "Empowering students: Practical knowledge and innovation.",
    "Nurturing future business leaders.",
  ];

  const achievements = [
    "Collaboration to host a G20 conference with an STPI fintech startup.",
    "LEED's participation in the CII Family Business Conclave",
  ];

  let samplepitch1 = [...pitches];
  samplepitch1 = samplepitch1
    .sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)))
    .reverse();
  const filteredPitches = samplepitch1
    .filter((pitch) => pitch.image.length > 0)
    .slice(0, 3);
  const verifiedLEEDevents = useSelector((state) => state.LEEDevents.value);
  let sampleLEEDevents = [...verifiedLEEDevents];
  sampleLEEDevents = sampleLEEDevents
    .sort((a, b) => new Date(b.startdate) - new Date(a.startdate))
    .slice(0, 2);
  const navigate = useNavigate();
  const [soicalmediaicon, setsocialmediaicon] = React.useState(0);
  const [Email, setEmail] = React.useState("");
  let box = React.createRef("");

  React.useEffect(() => {
    axios.get(`${url}/homepagevisit`)
      .then((res) => {
        // // console.log(res);
      })
      .catch((err) => {
        if (err.code === 'ECONNREFUSED') {
          console.error('Error: Unable to connect to the server. Please make sure the server is running.');
        } else {
          console.error('Error fetching data:', err.message);
          console.error('Error details:', err.response);
        }
      });
  }, []);

  React.useEffect(() => {
    let timer = setInterval(() => {
      if (window.scrollY > 800 && window.scrollY < 3200) {
        setsocialmediaicon(1);
      } else {
        setsocialmediaicon(0);
      }
    }, 1000);
    return () => {
      // // console.log("cleanup func executed");
      clearInterval(timer);
    };
  }, []);

  React.useEffect(() => {
    if (
      sessionStorage.getItem("userid") != null ||
      sessionStorage.getItem("userid") != undefined
    )
      setEmail(sessionStorage.getItem("email"));
    dispatch(setlogging(false));
    dispatch(setlogout(false));
  }, []);


  return (
    <div className="h-fit m-0 p-0">

      <div className="xs:h-[30vh] sm:h-[40vh] relative  lg:h-screen flex items-center justify-center w-screen ">
        <ImageHolderHome />
        <img
          src="/Assets/members-sm.jpg"
          className="absolute lg:pt-18  left-0 top-0 h-full w-full object-cover"
          alt="members image"
        />
      </div>
      {/* <div className="bg-kct-sm bg-cover bg-no-repeat h-[30vh] lg:h-fit">
      </div> */}
      <Hero />
      <PichtesCarousel filteredPitches={filteredPitches} />
      <EventCarousel leedEvents={sampleLEEDevents} />
      {/* <Divider /> */}
      <Processflow />
      <StudentEngageMent />
      <div className="flex m-0 flex-col gap-20 items-center justify-center px-5 lg:px-14 w-screen overflow-y-scroll snap-y snap-mandatory h-fit">
        <div className="w-full h-fit lg:h-screen ex flex-col items-center justify-center snap-start pt-20">
          <h2 className="text-4xl md:text-5xl mx-auto md:w-3/4 text-center font-extrabold  text-blue-500">
            <span className="block mb-2 text-3xl  font-semibold  text-teal-400 md:text-3xl drop-shadow-2xl">
              {" "}
              Our new goals
            </span>
            Explore opportunities with us!
          </h2>
          <div className="flex flex-col lg:flex-row gap-5 lg:gap-52 items-center py-20 justify-end w-full h-fit">
            <img
              src={goal}
              className="w-3/4 lg:w-1/4 lg:mr-32 mr-0 shadow-blue-400 rounded-3xl shadow-2xl"
              alt="kct event image"
            />
            <div className="flex flex-col pt-20  lg:pt-0  items-start  justify-center w-full lg:w-[50%]">
              {newGoals.map((goal) => (
                <div
                  key={goal}
                  className="text-blue-500 w-full text-lg flex  flex-row items-center justify-start gap-3">
                  <BsFillCheckCircleFill
                    className="inline w-fit mb-3 lg:mb-2 text-blue-500 "
                    size={20}
                  />
                  <p className="w-11/12">
                    {goal}
                  </p>
                </div>
              ))}
            </div>

          </div>
        </div>
        <div className="w-full h-fit lg:h-screen snap-start flex flex-col items-center ">
          <h2 className="text-3xl md:text-5xl w-full md:w-3/4 text-center font-extrabold text-blue-500">
            <span className="block mt-3 text-3xl  font-semibold  text-teal-400 md:text-3xl drop-shadow-2xl">
              {" "}
              Join our club !
            </span>
            Explore the world of aspiring entrepreneurs
          </h2>
          <div className="flex flex-col-reverse lg:flex-row gap-20 lg:gap-32 items-center py-20 justify-center w-full h-fit">
            <p className="w-full  italic lg:w-[40%]  text-center lg:text-right text-blue-500 text-lg  ">
              "Join LEED for an entrepreneurial journey. Connect with peers,
              access expertise, and gain startup experience. Whether budding
              entrepreneur or curious learner, be part of a community shaping
              the business future."
            </p>
            <img
              src={team}
              className="w-3/4 lg:w-1/4 shadow-blue-400 rounded-3xl shadow-2xl"
              alt="kct event image"
            />

          </div>
        </div>
        <div className="w-full flex flex-col items-center justify-center h-fit lg:h-screen snap-start ">
          <h2 className="text-4xl md:text-5xl mx-auto w-3/4 text-center font-extrabold  text-blue-500">
            <span className="block mb-2 text-3xl  font-semibold  text-teal-400 md:text-3xl drop-shadow-2xl">
              {" "}
              Our Milestones
            </span>
            What we had achieved

          </h2>


          <div className="flex flex-col lg:flex-row gap-5 lg:gap-52 items-center py-20 justify-end w-full h-fit">
            <img
              src={winning}
              className="w-3/4 lg:w-1/4 lg:mr-32 mr-0 shadow-blue-400 rounded-3xl shadow-2xl"
              alt="kct event image"
            />
            <div className="flex flex-col pt-20  lg:pt-0  items-start  justify-center w-full lg:w-[50%]">
              {achievements.map((achievement) => (
                <div
                  key={goal}
                  className="text-blue-500 w-full text-lg flex  flex-row items-center justify-start gap-3">
                  <BsFillCheckCircleFill
                    className="inline w-fit text-blue-500 mb-2"
                    size={20}
                  />
                  <p className="w-11/12">
                    {achievement}
                  </p>
                </div>
              ))}
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
