import React, { useEffect, useState } from "react";
import "../../Views/ST.css";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import linkedinimg from "../../Assets/linkedin.png";
import DateToDay from "../../Models/DateToDay";
const cryptojs = require("crypto-js");
function HomeST({ showCurrentUserPitch, userMail }) {
  let location = useLocation();
  const admins = useSelector((state) => state.admins.value);
  const pitches = useSelector((state) => state.pitch.value);
  const [showCurrentUserPitchState, setshowCurrentUserPitchState] = useState(showCurrentUserPitch);
  const [userMailState, setuserMailState] = useState(userMail);


  const leaderboardpitches = pitches.filter(
    (pitch) => pitch.report.length == 3
  );
  const [chosencategory, setchosencategory] = React.useState("All");
  const [showcateg, setshowcateg] = React.useState([]);
  const addnext = React.useRef(10);
  const [email, setEmail] = React.useState("");
  let s = new Date();
  // // console.log(pitches)

  useEffect(() => {
    setshowCurrentUserPitchState(showCurrentUserPitch);
  }, [showCurrentUserPitch])

  useEffect(() => {
    setuserMailState(userMail)
  }, [userMail])

  React.useEffect(() => {
    // if (pitches) {
    //   const topTen = leaderboardpitches
    //     .sort((a, b) => {
    //       const reportsSumA = a["report"].reduce(
    //         (sum, value) =>
    //           sum +
    //           Number(value["one"]) +
    //           Number(value["two"]) +
    //           Number(value["three"]) +
    //           Number(value["four"]) +
    //           Number(value["five"]) +
    //           Number(value["six"]) +
    //           Number(value["seven"]) +
    //           Number(value["eight"]) +
    //           Number(value["nine"]) +
    //           Number(value["ten"]) +
    //           a.upvotes.length,
    //         0
    //       );
    //       const reportsSumB = b["report"].reduce(
    //         (sum, value) =>
    //           sum +
    //           Number(value["one"]) +
    //           Number(value["two"]) +
    //           Number(value["three"]) +
    //           Number(value["four"]) +
    //           Number(value["five"]) +
    //           Number(value["six"]) +
    //           Number(value["seven"]) +
    //           Number(value["eight"]) +
    //           Number(value["nine"]) +
    //           Number(value["ten"]) +
    //           b.upvotes.length,
    //         0
    //       );
    //       return reportsSumB - reportsSumA; // Sort by reports sum in descending order
    //     })
    //     .slice(0, addnext.current);

    //   setshowcateg(pitchesa);
    // }

    setshowcateg(pitches)
  }, [pitches]);
  React.useEffect(() => {
    if (
      sessionStorage.getItem("email") !== null &&
      sessionStorage.getItem("email") !== undefined &&
      sessionStorage.getItem("userid") !== null &&
      sessionStorage.getItem("userid") !== undefined
    ) {
      var bytesemail = cryptojs.AES.decrypt(
        sessionStorage.getItem("email"),
        "kowshik123"
      );
      setEmail(() => bytesemail.toString(cryptojs.enc.Utf8));
    }
    if (location.state && location.state.created == "success") {
      toast.success("Success! Pitch created", {
        autoClose: 3000,
        position: toast.POSITION.BOTTOM_CENTER,
        className: "toast-message",
      });
    }
  }, []);
  function showcategory(val) {
    let dispPitch;
    if (val == "All") {
      //  dispPitch = leaderboardpitches.filter(pitch => pitch.createdAt.slice(5,7) == s.getMonth()+1);
      dispPitch = leaderboardpitches.filter(
        (pitch) => pitch.report.length == 3
      );
    } else {
      dispPitch = leaderboardpitches.filter((pitch) => pitch.category === val);
      // dispPitch = dispPitch.filter(pitch => pitch.createdAt.slice(5,7) == s.getMonth()+1);
      dispPitch = dispPitch.filter((pitch) => pitch.report.length == 3);
    }
    const topTen = dispPitch
      .sort((a, b) => {
        const reportsSumA = a["report"].reduce(
          (sum, value) =>
            sum +
            Number(value["one"]) +
            Number(value["two"]) +
            Number(value["three"]) +
            Number(value["four"]) +
            Number(value["five"]) +
            Number(value["six"]) +
            Number(value["seven"]) +
            Number(value["eight"]) +
            Number(value["nine"]) +
            Number(value["ten"]) +
            a.upvotes.length,
          0
        );
        const reportsSumB = b["report"].reduce(
          (sum, value) =>
            sum +
            Number(value["one"]) +
            Number(value["two"]) +
            Number(value["three"]) +
            Number(value["four"]) +
            Number(value["five"]) +
            Number(value["six"]) +
            Number(value["seven"]) +
            Number(value["eight"]) +
            Number(value["nine"]) +
            Number(value["ten"]) +
            b.upvotes.length,
          0
        );
        return reportsSumB - reportsSumA; // Sort by reports sum in descending order
      })
      .slice(0, addnext.current);

    setshowcateg(topTen);
  }
  return (
    <div className="w-screen flex justify-end">
      <div className="w-4/5 min-h-screen max-h-fit overflow-y-auto d-flex mt-28 flex-column align-items-center">
        <ToastContainer />
        <div className="table-container  min-h-screen max-h-fit grid grid-cols-1 md:grid-cols-2 gap-7 w-[100%] px-4 ">
          {showcateg.map((val, ind) => (showCurrentUserPitchState && showCurrentUserPitchState == true) ?
            (
              val["email"] == email &&
              <div key={ind} className="h-fit p-3  flex flex-row gap-3 items-center justify-stretch text-slate-600 shadow-sm rounded-2xl overflow-hidden border ">
                <div
                  className="w-1/3 h-60 bg-slate-100 rounded-2xl"
                ></div>
                <div>
                  <div
                    className=" text-white rounded-2xl bg-gradient-to-tr from-teal-400 to-blue-400 p-3"

                  >
                    <div className="table-value left-0 text-2xl flex-row flex font-bold justify-between">
                      <div>
                        {val["title"]}
                      </div>
                      <div>
                        <p
                          className="whitespace-nowrap"
                          style={{ fontSize: 10, textShadow: "none" }}
                        >
                          {DateToDay(val["createdAt"].slice(0, 10))}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card-body text-slate-500 p-3">
                    <div className="flex flex-row font-semibold mb-2">
                      <div className="table-heading  w-1/3">Candidate: &nbsp;</div>
                      <div className="table-value items-center justify-center">
                        {val["name"]}&nbsp;
                        {val["linkedin"] && val["linkedin"].length > 0 && (
                          <a
                            target="_blank"
                            style={{ textDecoration: "none" }}
                            href={val["linkedin"]}
                          >
                            <img
                              src={linkedinimg}
                              alt="LinkedIn"
                              className="w-10 h-10"
                            />
                          </a>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-row font-semibold mb-2">
                      <div className="table-heading  w-1/3">Points: &nbsp;</div>
                      <div className="table-value">
                        {val["report"].reduce((sum, value) => {
                          return (
                            sum +
                            Number(value["one"]) +
                            Number(value["two"]) +
                            Number(value["three"]) +
                            Number(value["four"]) +
                            Number(value["five"]) +
                            Number(value["six"]) +
                            Number(value["seven"]) +
                            Number(value["eight"]) +
                            Number(value["nine"]) +
                            Number(value["ten"])
                          );
                        }, 0) + val["upvotes"].length}
                      </div>
                    </div>
                    <div className="flex flex-row font-semibold mb-2">
                      <div className="table-heading  w-1/3">Category: &nbsp;</div>
                      <div className="table-value">{val["category"]}</div>
                    </div>
                  </div>
                </div>
              </div>
            ) :
            (
              <div key={ind} className="h-fit p-3  flex flex-row gap-3 items-center justify-stretch text-slate-600 shadow-sm rounded-2xl overflow-hidden border ">
                <div
                  className="w-1/3 h-60 bg-slate-100 rounded-2xl"
                ></div>
                <div>
                  <div
                    className=" text-white rounded-2xl bg-gradient-to-tr from-teal-400 to-blue-400 p-3"

                  >
                    <div className="table-value left-0 text-2xl flex-row flex font-bold justify-between">
                      <div>
                        {val["title"]}
                      </div>
                      <div>
                        <p
                          className="whitespace-nowrap"
                          style={{ fontSize: 10, textShadow: "none" }}
                        >
                          {DateToDay(val["createdAt"].slice(0, 10))}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card-body text-slate-500 p-3">
                    <div className="flex flex-row font-semibold mb-2">
                      <div className="table-heading  w-1/3">Candidate: &nbsp;</div>
                      <div className="table-value items-center justify-center">
                        {val["name"]}&nbsp;
                        {val["linkedin"] && val["linkedin"].length > 0 && (
                          <a
                            target="_blank"
                            style={{ textDecoration: "none" }}
                            href={val["linkedin"]}
                          >
                            <img
                              src={linkedinimg}
                              alt="LinkedIn"
                              className="w-10 h-10"
                            />
                          </a>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-row font-semibold mb-2">
                      <div className="table-heading  w-1/3">Points: &nbsp;</div>
                      <div className="table-value">
                        {val["report"].reduce((sum, value) => {
                          return (
                            sum +
                            Number(value["one"]) +
                            Number(value["two"]) +
                            Number(value["three"]) +
                            Number(value["four"]) +
                            Number(value["five"]) +
                            Number(value["six"]) +
                            Number(value["seven"]) +
                            Number(value["eight"]) +
                            Number(value["nine"]) +
                            Number(value["ten"])
                          );
                        }, 0) + val["upvotes"].length}
                      </div>
                    </div>
                    <div className="flex flex-row font-semibold mb-2">
                      <div className="table-heading  w-1/3">Category: &nbsp;</div>
                      <div className="table-value">{val["category"]}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

        </div>
      </div>
    </div >
  );
}

export default HomeST;
