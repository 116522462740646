import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import logopic from "../Assets/logo.png";
import signoutpic from "../Assets/sign-out.png";
import { useSelector, useDispatch } from "react-redux";
import MenuBar from "../components/MenuBar";
const cryptojs = require("crypto-js");
function NavInApp() {
  const experts = useSelector((state) => state.experts.value);
  const [userName, setuserName] = React.useState("");
  const [Email, setEmail] = React.useState("");
  const [userProfilePic, setUserProfilePic] = React.useState("");
  const [opennav, setopennav] = React.useState(false);
  React.useEffect(() => {
    //decrypting
    if (
      sessionStorage.getItem("name") !== null &&
      sessionStorage.getItem("email") !== undefined
      // sessionStorage.getItem("pic") !== null
    ) {
      var bytes = cryptojs.AES.decrypt(
        sessionStorage.getItem("name"),
        "kowshik123"
      );
      setuserName(() => bytes.toString(cryptojs.enc.Utf8));
      var bytesemail = cryptojs.AES.decrypt(
        sessionStorage.getItem("email"),
        "kowshik123"
      );
      setEmail(() => bytesemail.toString(cryptojs.enc.Utf8));
      if (sessionStorage.getItem("pic") !== null) {
        var bytesimage = cryptojs.AES.decrypt(
          JSON.parse(sessionStorage.getItem("pic")),
          "kowshik123"
        );
        setUserProfilePic(() => bytesimage?.toString(cryptojs.enc.Utf8));
      }
    } else {
      setuserName("");
      setUserProfilePic("");
      setEmail("");
    }
  }, [
    sessionStorage.getItem("name"),
    sessionStorage.getItem("email"),
    sessionStorage.getItem("pic"),
  ]);

  const navLinks = [
    // logo
    {
      name: "Home",
      link: "/",
    },
    {
      name: "About Us",
      link: "/aboutus",
    },
    {
      name: "Events",
      link: "/events",
    },
    {
      name: "Contact",
      link: "contact",
    },
    {
      name: "Team",
      link: "/team",
    },
    {
      name: "Flagship",
      link: "aboutus",
    },

    // login
  ];
  React.useEffect(() => {
    //decrypting
    if (
      sessionStorage.getItem("name") !== null &&
      sessionStorage.getItem("email") !== undefined
      // sessionStorage.getItem("pic") !== null
    ) {
      var bytes = cryptojs.AES.decrypt(
        sessionStorage.getItem("name"),
        "kowshik123"
      );
      setuserName(() => bytes.toString(cryptojs.enc.Utf8));
      var bytesemail = cryptojs.AES.decrypt(
        sessionStorage.getItem("email"),
        "kowshik123"
      );
      setEmail(() => bytesemail.toString(cryptojs.enc.Utf8));
      if (sessionStorage.getItem("pic") !== null) {
        var bytesimage = cryptojs.AES.decrypt(
          JSON.parse(sessionStorage.getItem("pic")),
          "kowshik123"
        );
        setUserProfilePic(() => bytesimage?.toString(cryptojs.enc.Utf8));
      }
    } else {
      setuserName("");
      setUserProfilePic("");
      setEmail("");
    }
  }, []);

  return (
    <div className="w-screen text-white fixed left-0 top-0 mb-0 flex flex-row items-center justify-between px-4 lg:justify-evenly h-fit py-3 shadow-2xl  lg:px-40 bg-gradient-to-r from-teal-400 to-blue-400  z-50">
      <div className="flex gap-6 flex-row justify-center items-center">
        <MenuBar />
        <a href="/">
          <img
            src={logopic}
            alt="LEED logo"
            className="h-11 bg-white rounded-md "
          />
        </a>
        {/* <img className="text-3xl m-0 font-extrabold">.LEED/</img>> */}
      </div>
      <div className="hidden lg:flex flex-row items-center gap-10">
        {navLinks.map((link) => (
          <Link
            key={link.name}
            className={`text-white shadow-none hover:underline font-extrabold italic   no-underline text-md ${
              link.name == "Flagship" &&
              " bg-slate-700 text-white font-normal px-2 py-2 rounded-xl "
            }`}
            to={
              link.name == "Flagship"
                ? Email == experts.expert1 ||
                  Email == experts.expert2 ||
                  Email == experts.expert3
                  ? "/flagshipexpert"
                  : Email.length > 0
                  ? "/flagship"
                  : "/login"
                : link.link
            }
          >
            {link.name}
          </Link>
        ))}
      </div>
      {userName.length > 0 ? (
        <NavDropdown
          style={{ marginRight: 40 }}
          title={userName}
          id="basic-nav-dropdown"
        >
          <NavDropdown.Item href="#action/3.2">
            <div className="flex flex-col items-center justify-center">
              <img
                src={userProfilePic}
                style={{ borderRadius: "50%", width: 30, height: 30 }}
                alt=""
              />
              <div className="mx-1 no-underline">{userName}</div>
            </div>
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item
            onClick={() => setopennav(!opennav)}
            href="/login"
            className="bg-red-50"
          >
            <Link
              className="mx-auto h-full w-full p-0  no-underline"
              to="/login"
            >
              SIGN OUT
            </Link>
          </NavDropdown.Item>
        </NavDropdown>
      ) : (
        <Link
          to="/login"
          className="bg-white/20 text-white fon no-underline text-md px-3 py-2 rounded-xl"
        >
          Login
        </Link>
      )}
    </div>
  );
}

export default NavInApp;
