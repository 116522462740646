import React from 'react'
import DateToDay from './DateToDay'
import './eventTable.css'
function EventTable({ val }) {
  return (
    <div className='d-flex justify-content-center' style={{ width: '95vw', maxHeight: '400px', overflowY: 'scroll' }}>
      <table className='bg-light ' style={{ display: "block", width: '100%', overflowX: 'scroll', fontFamily: 'Inter', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th>Event</th>
            <th>Description</th>
            <th>Start Date(24hrs)</th>
            <th>End Date(24hrs)</th>
          </tr>
        </thead>
        <tbody>
          {val.map((value, index) =>
            <tr key={index}>
              <td style={{ padding: '10px' }}><a href={value['Link']}>{value['Title']}</a></td>
              <td style={{ padding: '10px' }}>{value['Desc']}</td>
              <td style={{ padding: '10px' }}>{DateToDay(value['Date'].slice(0, 10))}&nbsp;{value['Date'].slice(11)}</td>
              <td style={{ padding: '10px' }}>{value['endDate'] && DateToDay(value['Date'].slice(0, 10))}&nbsp;{value['Date'].slice(11)}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default EventTable
