import React, { useEffect, useState } from "react";
import { BsTelephone } from "react-icons/bs";

const EventsBox = ({ categoryTitle, categoryTitleEvents }) => {
  const [title, settitle] = useState(categoryTitle);
  const [events, setevents] = useState(categoryTitleEvents);

  useEffect(() => {
    settitle(categoryTitle);
    setevents(categoryTitleEvents);
  }, []);

  useEffect(() => {
    settitle(categoryTitle);
    setevents(categoryTitleEvents);
  }, [categoryTitle, categoryTitleEvents]);

  // // console.log(events);
  return (
    <div className="w-full lg:w-4/5  flex justify-end">
      <div className="w-full lg:py-16 min-h-screen overflow-y-scroll py-20 max-h-fit">
        {/* <h1 className="text-5xl text-blue-500 w-screen text-center">{title}</h1> */}
        <div className="lg:w-[99%]  gap-10  w-screen mt-10 flex max-h-fit flex-col items-center justify-center lg:px-32 px-4">
          {events.length > 0 ? (
            events.map((card) => (
              <div className="flex h-fit border lg:w-full w-[100%] gap-10 lg:flex-row flex-col items-stretch justify-stretch bg-slate-100 shadow-xl px-10 py-10 rounded-3xl">
                <div className="flex-1  w-5/4">
                  {card.pic && card.pic.length > 0 ? (
                    <img
                      src={card.pic[0].secure_url}
                      className="h-full w-full object-contain"
                      alt="events image"
                    />
                  ) : (
                    <img
                      src={card.img}
                      className="h-full w-full object-contain"
                      alt="events image"
                    />
                  )}
                </div>
                <div className="lg:w-3/4 w-full  h-fit text-gray-400">
                  <p className="text-3xl text-blue-500 font-extrabold">
                    {card.title}
                  </p>
                  <p className="">{card.desc}</p>
                  {card.venue && (
                    <p className="text-blue-500">Venue: {card.venue}</p>
                  )}
                  {card.startdate && (
                    <p className="text-blue-500">
                      From: {new Date(card.startdate).toString()} -{" "}
                    </p>
                  )}
                  {card.enddate && (
                    <p className="text-blue-500">
                      To:{new Date(card.enddate).toString()}
                    </p>
                  )}
                  {card.contact && (
                    <div className="flex lg:flex-row flex-col  gap-3 w-full items-start justify-start">
                      {card.contact.length > 0 &&
                        card.contact.map((person) => (
                          <div className=" bg-blue-500  px-4 items-center gap-3 py-2 flex flex-row justify-center rounded-xl text-white ">
                            <BsTelephone size={30} className="text-white" />
                            <p className="m-0">{person.name} </p>
                            <p className="m-0"> {person.link}</p>
                          </div>
                        ))}
                    </div>
                  )}
                  <div className="mt-10">
                    <a
                      href={card.link ? card.link : ""}
                      className="px-4 text-2xl py-2 rounded-xl no-underline bg-gradient-to-tr from-blue-600 to-blue-300 text-white"
                    >
                      Apply
                    </a>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-xl font-semibold font-sans">
              No Events {title?.replaceAll(" Events", "")}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventsBox;
