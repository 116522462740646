import React, { useState } from "react";
import axios from "axios";
import { app } from "../Models/firebase";
import {
  OAuthProvider,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  deleteUser,
} from "firebase/auth";

import Modal from "react-bootstrap/Modal";
import { useNavigate, NavLink } from "react-router-dom";
import DateToDay from "../Models/DateToDay";
import { SidebarAdmin } from "../Models/sidebar";
import Dashboard from "./dashboard";
import adminpic from "../Assets/user.png";
import Helpdesk from "./helpdesk";
import LEEDeventrequests from "./LEEDeventrequests";
import Createevents from "./createevents";
import { useSelector } from "react-redux";
import EventsBox from "../components/EventsBox";
import {
  TbLayoutSidebarRightCollapseFilled,
  TbLayoutSidebarLeftCollapseFilled,
} from "react-icons/tb";

var cryptojs = require("crypto-js");

function Requests() {
  const url = useSelector((state) => state.url.value);
  const admins = useSelector((state) => state.admins.value);
  const [renderevents, setrenderevents] = React.useState("returnallevents");
  const navigate = useNavigate();
  const auth = getAuth(app);
  const [pendingdata, setpendingdata] = React.useState([]);
  const [open, setopen] = React.useState(false);
  const [UIC, setUIC] = React.useState(false);
  const [result, setresult] = React.useState({});
  const [accessToken, setaccessToken] = React.useState("");
  const [Email, setEmail] = React.useState("");
  const [blockedrequests, setblockedrequests] = React.useState([]);
  const [pendingregisters, setpendingregisters] = React.useState([]);
  const [LEEDevents, setLEEDevents] = React.useState([]);
  const verifiedLEEDevents = useSelector((state) => state.LEEDevents.value);
  const [verifiedevents, setVerifiedEvents] = useState([...verifiedLEEDevents]);
  const [bgcolor, setBgColor] = useState("");
  const [isHidden, setIsHidden] = useState(false);
  const [ActiveMenu, setActiveMenu] = useState(false);

  const choosepicker = (msg) => {
    setrenderevents(msg);
  };
  React.useEffect(() => {
    if (
      sessionStorage.getItem("email") == undefined &&
      sessionStorage.getItem("verifyuic") == null
    ) {
      VerifyAdmin();
    } else if (
      sessionStorage.getItem("email") &&
      sessionStorage.getItem("verifyuic")
    ) {
      var bytesemail = cryptojs.AES.decrypt(
        sessionStorage.getItem("email"),
        "kowshik123"
      );
      if (
        bytesemail.toString(cryptojs.enc.Utf8) != admins.admin1 &&
        bytesemail.toString(cryptojs.enc.Utf8) != admins.admin2
      ) {
        navigate("/");
      }
    } else {
      // console.log(sessionStorage.getItem("email"))
      // console.log(sessionStorage.getItem("verifyuic"))
      setopen(true)
    }
    if (window.innerWidth < 800) {
      alert("Kindly switch to PC device(screenSize >800px) and Reload page ! ");
    }
  }, []);

  React.useEffect(() => {
    if (
      sessionStorage.getItem("pic") !== null &&
      sessionStorage.getItem("pic") !== undefined
    ) {
      var bytesemail = cryptojs.AES.decrypt(
        sessionStorage.getItem("email"),
        "kowshik123"
      );
      setEmail(() => bytesemail.toString(cryptojs.enc.Utf8));
    }
  }, []);
  React.useEffect(() => {
    axios({
      method: "GET",
      url: `${url}/pendingpitchST`,
    })
      .then((res) => {
        res.data.docs = JSON.parse(
          cryptojs.AES.decrypt(res.data.docs, "kowshik123").toString(
            cryptojs.enc.Utf8
          )
        );
        res.data.docs.map((value, index) => {
          if (res.data.docs.length !== pendingdata.length) {
            if (Email == admins.admin1) {
              if (value["admin1"] == false) {
                setpendingdata((prev) => [...prev, value]);
              }
            } else if (Email == admins.admin2) {
              if (value["admin2"] == false) {
                setpendingdata((prev) => [...prev, value]);
              }
            }
          }
        });
      })
      .catch((err) => {
        // // console.log(err);
      });
  }, [Email == admins.admin1 || Email == admins.admin2]);
  React.useEffect(() => {
    if (Email.length > 0) {
      axios({
        method: "GET",
        url: `${url}/getpendingregisters`,
      })
        .then((res) => {
          res.data.docs = JSON.parse(
            cryptojs.AES.decrypt(res.data.docs, "kowshik123").toString(
              cryptojs.enc.Utf8
            )
          );
          res.data.docs.map((val) => {
            if (!val.admin) {
              setpendingregisters((prev) =>
                prev.includes(val) ? prev : [...prev, val]
              );
            } else if (val.admin) {
              if (val.admin != Email) {
                setpendingregisters((prev) =>
                  prev.includes(val) ? prev : [...prev, val]
                );
              }
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
      axios({
        method: "GET",
        url: `${url}/getpendingblockedusers`,
      })
        .then((res) => {
          res.data.docs = JSON.parse(
            cryptojs.AES.decrypt(res.data.docs, "kowshik123").toString(
              cryptojs.enc.Utf8
            )
          );
          res.data.docs.map((val, ind) => {
            if (val.admin != Email) {
              setblockedrequests((prev) => [...prev, val]);
            }
          });
        })
        .catch((err) => {
          // // console.log(err);
        });
    }
  }, [Email]);
  function VerifyAdmin() {
    const microsoftProvider = new OAuthProvider("microsoft.com");
    microsoftProvider.setCustomParameters({
      // Optional "tenant" parameter in case you are using an Azure AD tenant.
      // eg. '8eaef023-2b34-4da1-9baa-8bc8c9d6a490' or 'contoso.onmicrosoft.com'
      // or "common" for tenant-independent tokens.
      // The default value is "common".
      tenant: "6b8b8296-bdff-4ad8-93ad-84bcbf3842f5",
      prompt: "select_account",
    });
    signInWithPopup(auth, microsoftProvider)
      .then((result) => {
        // User is signed in.
        // IdP data available in result.additionalUserInfo.profile.
        // Get the OAuth access token and ID Token
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;
        setresult(result);
        let name = cryptojs.AES.encrypt(
          result.user.displayName,
          "kowshik123"
        ).toString();
        sessionStorage.setItem("name", name);
        let email = cryptojs.AES.encrypt(
          result.user.email,
          "kowshik123"
        ).toString();
        sessionStorage.setItem("email", email);
        const lookupMsAzureProfilePhoto = (accessToken) => {
          fetch("https://graph.microsoft.com/v1.0/me/photo/$value", {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "image/jpg",
            },
          })
            .then(function (response) {
              return response.blob();
            })
            .then(function (blob) {
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onload = async () => {
                const base64data = reader.result;
                let profilePic = cryptojs.AES.encrypt(
                  base64data,
                  "kowshik123"
                ).toString();
                sessionStorage.setItem("pic", JSON.stringify(profilePic));
                await axios
                  .post(`${url}/createuser`, {
                    name: result.user.displayName,
                    email: result.user.email,
                    pic: base64data,
                  })
                  .then((res) => {
                    let userid = cryptojs.AES.encrypt(
                      res.data.id,
                      "kowshik123"
                    ).toString();
                    sessionStorage.setItem("userid", JSON.stringify(userid));
                    // let verifyuic = cryptojs.AES.encrypt(
                    //   "verified",
                    //   "kowshik123"
                    // ).toString();
                    // sessionStorage.setItem(
                    //   "verifyuic",
                    //   JSON.stringify(verifyuic)
                    // );
                  });
                window.location.reload();
              };
            })
            .catch((e) => {
              // // console.log("error injecting photo");
              navigate("/");
            });
        };
        lookupMsAzureProfilePhoto(accessToken);
        setaccessToken(accessToken);
        if (
          result.user.email == admins.admin1 ||
          result.user.email == admins.admin2
        ) {
          setEmail(result.user.email)
          setopen(true);
        } else {
          navigate("/");
        }
      })
      .catch((err) => navigate("/"));
  }
  function VerifyUIC(e) {
    e.preventDefault();
    setopen(false);
    if (
      (Email == admins.admin1 && UIC == "K2O0W0S2H0I7K2R6A0N") ||
      (Email == admins.admin2 && UIC == "H2A0R0I2H0A7A1R7A0N")
    ) {

      // fetchDataFromBackend()
    } else {
      navigate("/");
    }
  }

  React.useEffect(() => {
    addaxiosfunction();
  }, [Email == admins.admin2 || Email == admins.admin1]);
  function addaxiosfunction() {
    axios
      .post(`${url}/getpendingKCTLEEDevents`, {
        email: Email,
      })
      .then((res) => {
        res.data.docs = JSON.parse(
          cryptojs.AES.decrypt(res.data.docs, "kowshik123").toString(
            cryptojs.enc.Utf8
          )
        )
        // // console.log(res.data.docs)
        res.data.docs.map((value, index) => {
          if (value["email"] != Email)
            setLEEDevents((prev) => [...prev, value]);
        });
      }).catch((err) => {
        console.log(err)
      })
  }
  return (
    <div className="">
      <Modal
        style={{ textAlign: "center", fontFamily: "Inter" }}
        show={open}
        onHide={() => setopen(false)}
        backdrop="static"
        size="md"
        centered
      >
        <Modal.Title className="text-primary">
          Enter 16 digit Unique Identity Code
        </Modal.Title>
        <Modal.Body>
          <form
            action=""
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            onSubmit={VerifyUIC}
          >
            <input
              type="text"
              className="form-control"
              style={{ width: "60%", fontSize: 20 }}
              onChange={(e) => setUIC(e.target.value)}
            />{" "}
            <br />
            <input type="submit" value="Submit" className="btn btn-primary" />
          </form>
        </Modal.Body>
      </Modal>
      {(Email == admins.admin1 || Email == admins.admin2) && (
        <div className="flex  flex-row justify-between w-screen items-start ">
          <button
            onClick={() => {
              setIsHidden(true);
            }}
            className="lg:hidden block absolute"
          >
            <TbLayoutSidebarRightCollapseFilled color="blue" size={50} />
          </button>
          <div className="w-1/5 fixed  z-1 lg:flex-row flex  py-10 lg:pt-10 pt-20 h-screen ">
            <div
              className={`w-full z-1 min-h-screen fixed lg:hidden block top-0 bg-gradient-to-l from-blue-500 to-teal-500 ${isHidden == false ? "-left-full" : "left-0"
                }`}
            >
              <button
                onClick={() => {
                  setIsHidden((prev) => !prev);
                }}
                className="text-4xl lg:hidden block pt-20"
              >
                <TbLayoutSidebarLeftCollapseFilled color="white" size={50} />
              </button>
              <div className="lg:pl-96 pl-20 justify-evenly">
                <NavLink
                  className={`no-underline ${ActiveMenu == "allevents" ? "text-warning" : "text-light"
                    }`}
                  onClick={(e) => {
                    choosepicker("returnallevents");
                    setActiveMenu("allevents");
                  }}
                >
                  <p>Dashboard</p>
                </NavLink>

                <NavLink
                  className={`no-underline ${ActiveMenu == "todayevents" ? "text-warning" : "text-light"
                    }`}
                  onClick={() => {
                    choosepicker("returntodayevents");
                    setActiveMenu("todayevents");
                  }}
                >
                  <p>Flagship Events</p>
                </NavLink>
                <NavLink
                  className={`no-underline ${ActiveMenu == "futureevents" ? "text-warning" : "text-light"
                    }`}
                  onClick={() => {
                    choosepicker("returnfutureevents");
                    setActiveMenu("futureevents");
                  }}
                >
                  <p>Event Notifications</p>
                </NavLink>
                <NavLink
                  className={`no-underline ${ActiveMenu == "overevents" ? "text-warning" : "text-light"
                    }`}
                  onClick={() => {
                    choosepicker("returncompletedevents");
                    setActiveMenu("overevents");
                  }}
                >
                  <p>Create Events</p>
                </NavLink>
              </div>
            </div>
            <div
              className={`w-full z-1 min-h-screen relative justify-evenly lg:block hidden bg-gradient-to-l from-blue-500 to-teal-500`}
            >
              <button
                onClick={() => {
                  setIsHidden((prev) => !prev);
                }}
                className="text-4xl lg:hidden block pt-20"
              >
                <TbLayoutSidebarLeftCollapseFilled color="white" size={50} />
              </button>
              <div className="pt-80 pl-20">
                <NavLink
                  className={`no-underline ${ActiveMenu == "allevents" ? "text-warning" : "text-light"
                    }`}
                  onClick={(e) => {
                    choosepicker("returnallevents");
                    setActiveMenu("allevents");
                  }}
                >
                  <p>Dashboard</p>
                </NavLink>

                <NavLink
                  className={`no-underline ${ActiveMenu == "todayevents" ? "text-warning" : "text-light"
                    }`}
                  onClick={() => {
                    choosepicker("returntodayevents");
                    setActiveMenu("todayevents");
                  }}
                >
                  <p>Flagship Events</p>
                </NavLink>
                <NavLink
                  className={`no-underline ${ActiveMenu == "futureevents" ? "text-warning" : "text-light"
                    }`}
                  onClick={() => {
                    choosepicker("returnfutureevents");
                    setActiveMenu("futureevents");
                  }}
                >
                  <p>Event Notifications</p>
                </NavLink>
                <NavLink
                  className={`no-underline ${ActiveMenu == "overevents" ? "text-warning" : "text-light"
                    }`}
                  onClick={() => {
                    choosepicker("returncompletedevents");
                    setActiveMenu("overevents");
                  }}
                >
                  <p>Create Events</p>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="w-screen flex justify-end items-end">
            <div className="w-4/5 mt-5  lg:pt-10 lg:px-60 pt-24 ">
              <h3 style={{ fontFamily: "Oswald", fontSize: 30 }}>
                <img src={adminpic} style={{ width: 20, height: 20 }} /> ADMIN
                PAGE LEED
              </h3>
              <hr />
              {renderevents == "returnallevents" && <Dashboard />}
              {renderevents == "returntodayevents" &&
                (pendingdata.length > 0 ? (
                  pendingdata.map((value, index) => (
                    <div
                      className="border d-flex flex-column align-items-center mt-3 p-2"
                      style={{ width: "95%" }}
                    >
                      <h5
                        className=" w-100 text-start"
                        style={{
                          textDecoration: "underline",
                          textUnderlineOffset: "5px",
                        }}
                      >
                        Account :
                        <a
                          rel="noreferrer"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                          href={`mailto:${value["email"]}`}
                        >
                          {" "}
                          {value["email"]}
                        </a>
                      </h5>
                      <br />
                      <div className="d-flex w-100 pitch_profile justify-content-around align-items-center">
                        <div className="d-flex justify-content-center align-items-end">
                          <img
                            src={value["pic"]}
                            style={{
                              borderRadius: "50%",
                              width: 40,
                              height: 40,
                            }}
                            alt=""
                          />
                          &nbsp;&nbsp;
                          <i>
                            <h4 className="text-muted">{value["name"]}</h4>
                          </i>
                        </div>{" "}
                        &nbsp;&nbsp;
                        <p>{value["category"]}</p>
                        <p>{DateToDay(value["createdAt"].slice(0, 10))}</p>
                      </div>
                      <h4>{value["title"]}</h4>
                      <p style={{ fontFamily: "inherit" }}>
                        {" "}
                        {value["content"]}
                      </p>
                      {value["users"].length > 0 && (
                        <div className="w-100 " style={{ textAlign: "start" }}>
                          <h3 className="text-secondary">Target Users</h3>
                          <p>{value["users"]}</p>
                        </div>
                      )}
                      {value["impact"].length > 0 && (
                        <div className="w-100 " style={{ textAlign: "start" }}>
                          <h3 className="text-secondary">Solution Impact</h3>
                          <p>{value["impact"]}</p>
                        </div>
                      )}
                      {value["barriers"].length > 0 && (
                        <div className="w-100 " style={{ textAlign: "start" }}>
                          <h3 className="text-secondary">Adoption Barriers</h3>
                          <p>{value["barriers"]}</p>
                        </div>
                      )}

                      {value["image"].map((val, ind) => (
                        <>
                          <img
                            src={val["secure_url"]}
                            style={{
                              width: "90%",
                              height: 400,
                              objectFit: "contain",
                            }}
                            alt=""
                          />{" "}
                          <br />
                        </>
                      ))}
                      <br />
                      {value["video"].map((val, ind) => (
                        <>
                          <video
                            src={val["secure_url"]}
                            style={{
                              width: "90%",
                              maxHeight: "70vh",
                              border: "1px solid black",
                            }}
                            controls
                          ></video>{" "}
                          <br />
                        </>
                      ))}
                      {value["gdrive"].length > 0 &&
                        value["gdrive"].map((val, ind) => {
                          return (
                            <div className="w-75">
                              <embed
                                src={val.replace(
                                  "/view?usp=sharing",
                                  "/preview"
                                )}
                                style={{ width: "100%" }}
                                height="400"
                              ></embed>{" "}
                              <br />{" "}
                              <a
                                style={{ fontWeight: "600" }}
                                href={val}
                                target="_blank"
                              >
                                DOWNLOAD DOCUMENT
                              </a>{" "}
                              <br />
                            </div>
                          );
                        })}
                      <div className="w-100 d-flex justify-content-evenly my-3">
                        <button
                          onClick={() => {
                            if (
                              window.confirm(
                                "Confirm again to ACCEPT the pitch"
                              )
                            ) {
                              if (Email == admins.admin1) {
                                axios.post(`${url}/requestacceptST`, {
                                  id: value["_id"],
                                  admin: "admin1",
                                }).then().catch((err) => {
                                  console.log(err)
                                })
                              } else if (Email == admins.admin2) {
                                axios.post(`${url}/requestacceptST`, {
                                  id: value["_id"],
                                  admin: "admin2",
                                }).then().catch((err) => {
                                  console.log(err)
                                })
                              }
                              window.location.reload();
                            }
                          }}
                          className="btn btn-success"
                        >
                          Accept
                        </button>
                        <button
                          onClick={() => {
                            if (
                              window.confirm(
                                "Confirm again to REJECT the pitch"
                              )
                            ) {
                              axios.post(`${url}/requestrejectST`, {
                                id: value["_id"],
                              }).then().catch((err) => {
                                console.log(err)
                              })
                              window.location.reload();
                            }
                          }}
                          className="btn btn-danger"
                        >
                          Reject
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <h4>
                    <i>No Pending Flagship Requests</i>
                  </h4>
                ))}
              <div>
                {renderevents == "returnfutureevents" && (
                  <div className="d-flex flex-column align-items-center">
                    {blockedrequests && blockedrequests.length > 0 && blockedrequests.map((value, index) => (
                      <div className="d-flex flex-column">
                        <h2 className="text-danger">BLOCK USER REQUEST</h2>
                        <h3>{value["Useremail"]}</h3>
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            if (window.confirm("Confirm Again to Block")) {
                              axios.post(`${url}/acceptblockuser`, {
                                id: value["_id"],
                              }).then().catch((err) => {
                                console.log(err)
                              })
                              window.location.reload();
                            }
                          }}
                        >
                          BLOCK
                        </button>
                        <hr className="w-100" />
                      </div>
                    ))}
                    <h3>
                      <u>Event Registers Requested</u>{" "}
                    </h3>
                    {pendingregisters.length > 0 ? (
                      <>
                        {pendingregisters.map((value, index) => (
                          <div
                            className="my-5 w-75 p-2"
                            style={{ boxShadow: "0 0 10px grey" }}
                          >
                            <h5>
                              From <b>{value["Institution"]}</b>
                            </h5>
                            <p
                              style={{
                                float: "left",
                                border: "1px solid black",
                                padding: "1%",
                              }}
                            >
                              ACCOUNT -{" "}
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href={`mailto:${value["Account"]}`}
                              >
                                {value["Account"]}
                              </a>
                            </p>{" "}
                            <br />
                            <br />
                            <p className="w-100 d-flex justify-content-start">
                              Person To Contact -{" "}
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href={`mailto:${value["Email"]}`}
                              >
                                {value["Email"]}
                              </a>
                            </p>
                            <br />
                            <h5>{value["Title"]}</h5>
                            <p>{value["Desc"]}</p>
                            {value["Link"] != null && (
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={value["Link"]}
                              >
                                Registeration/Redirect Link
                              </a>
                            )}
                            <p>
                              <i>
                                Date Of Event :{" "}
                                {DateToDay(value["Date"].slice(0, 10))}&nbsp;
                                {value["Date"].slice(11)}
                              </i>{" "}
                              -
                              <i>
                                {DateToDay(value["endDate"].slice(0, 10))}&nbsp;
                                {value["endDate"].slice(11)}
                              </i>
                            </p>
                            <button
                              className="btn btn-success  mx-5"
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Confirm again to ACCEPT the EVENT"
                                  )
                                ) {
                                  axios.post(`${url}/accepteventrequest`, {
                                    id: value["_id"],
                                    email: Email,
                                  }).then().catch((err) => {
                                    console.log(err)
                                  })
                                  setpendingregisters((prevArray) =>
                                    prevArray.filter(
                                      (obj) => obj["_id"] !== value["_id"]
                                    )
                                  );
                                }
                              }}
                            >
                              Accept
                            </button>
                            <button
                              className="btn btn-danger"
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Confirm again to REJECT the EVENT"
                                  )
                                ) {
                                  axios.post(`${url}/rejecteventrequest`, {
                                    id: value["_id"],
                                  }).then().catch((err) => {
                                    console.log(err)
                                  })
                                  setpendingregisters((prevArray) =>
                                    prevArray.filter(
                                      (obj) => obj["_id"] !== value["_id"]
                                    )
                                  );
                                }
                              }}
                            >
                              Reject
                            </button>
                            <br />
                          </div>
                        ))}
                      </>
                    ) : (
                      <h4>
                        <i>No Events Requests</i>
                      </h4>
                    )}
                    <br />
                    <br />
                    <h3>
                      <u>Upcoming KCT LEED EVENTS</u>
                    </h3>
                    <LEEDeventrequests LEEDevents={LEEDevents} />

                    <br />
                  </div>
                )}
              </div>
              {renderevents == "returncompletedevents" && (
                <Createevents Email={Email} />
              )}
              {renderevents == "returnhelpdesk" && <Helpdesk Email={Email} />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Requests;
