import { createSlice } from "@reduxjs/toolkit";


export const url = createSlice({
  name: "url",
  initialState: { value: 'https://leedofficial-c591.vercel.app' },
  reducers: {
    seturl: (state, action) => {
      state.value = action.payload
    }
  }
});
export const { seturl } = url.actions;
export const urlreducer = url.reducer;

export const experts = createSlice({
  name: "experts",
  initialState: { value: { expert1: 'suryaprakash.20cs@kct.ac.in', expert2: 'jeevankumar.20ei@kct.ac.in', expert3: 'harihaaran.20ei@kct.ac.in' } },
  reducers: {
    setexperts: (state, action) => {
      state.value = action.payload
    }
  }
});
export const { setexperts } = experts.actions;
export const expertreducer = experts.reducer;

export const admins = createSlice({
  name: "admins",
  initialState: { value: { admin1: 'pugalarasan.20cs@kct.ac.in', admin2: 'suryaprakash.20cs@kct.ac.in' } },

});



export const adminsreducer = admins.reducer;

export const logging = createSlice({
  name: "logging",
  initialState: { value: false },
  reducers: {
    setlogging: (state, action) => {
      state.value = action.payload
    }
  }
});

export const { setlogging } = logging.actions;
export const userlogging = logging.reducer;


export const loggout = createSlice({
  name: "loggout",
  initialState: { value: false },
  reducers: {
    setlogout: (state, action) => {
      state.value = action.payload
    }
  }
});

export const { setlogout } = loggout.actions;
export const userlogout = loggout.reducer;

export const creatingpitch = createSlice({
  name: "creatingpitch",
  initialState: { value: false },
  reducers: {
    setcreatingpitch: (state, action) => {
      state.value = action.payload
    }
  }
});

export const { setcreatingpitch } = creatingpitch.actions;
export const usercreatingpitch = creatingpitch.reducer;

export const pitches = createSlice({
  name: "user",
  initialState: { value: [] },
  reducers: {
    setPitches: (state, action) => {
      state.value = action.payload
    }
  }
});

export const { setPitches } = pitches.actions;
export const userReducer = pitches.reducer;
export const expertpitches = createSlice({
  name: "expert",
  initialState: { value: [] },
  reducers: {
    setexpertPitches: (state, action) => {
      state.value = action.payload
    }
  }
});
export const { setexpertPitches } = expertpitches.actions;
export const expertpitch = expertpitches.reducer;

export const expertcomments = createSlice({
  name: "expertcomments",
  initialState: { value: [] },
  reducers: {
    setexpertcomments: (state, action) => {
      state.value = action.payload
    }
  }
});
export const { setexpertcomments } = expertcomments.actions;
export const expertcomment = expertcomments.reducer;

export const expertreports = createSlice({
  name: "expertreports",
  initialState: { value: [] },
  reducers: {
    setexpertreports: (state, action) => {
      state.value = action.payload
    }
  }
});
export const { setexpertreports } = expertreports.actions;
export const expertreport = expertreports.reducer;

export const yourpitch = createSlice({
  name: "yourpitch",
  initialState: { value: [] },
  reducers: {
    setyourpitch: (state, action) => {
      state.value = action.payload
    }
  }
})
export const { setyourpitch } = yourpitch.actions;
export const yourpitches = yourpitch.reducer;

export const GenEvents = createSlice({
  name: "GenEvents",
  initialState: { value: [] },
  reducers: {
    setGenEvents: (state, action) => {
      state.value = action.payload
    }
  }
})
export const { setGenEvents } = GenEvents.actions;
export const GenEvent = GenEvents.reducer;

export const LEEDEvents = createSlice({
  name: "LEEDEvents",
  initialState: { value: [] },
  reducers: {
    setLEEDEvents: (state, action) => {
      state.value = action.payload
    }
  }
})
export const { setLEEDEvents } = LEEDEvents.actions;
export const LEEDEvent = LEEDEvents.reducer;
