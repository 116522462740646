import React from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  PieChart,
  Pie,
  Legend,
  Cell,
} from "recharts";
import axios from "axios";
import { useSelector } from "react-redux";
const cryptojs = require("crypto-js");
function Dashboard() {
  const pitches = useSelector((state) => state.pitch.value);
  const url = useSelector((state) => state.url.value);
  const verifiedLEEDevents = useSelector((state) => state.LEEDevents.value);
  const verifiedevents = useSelector((state) => state.generalevents.value);
  const [homeviews, sethomeviews] = React.useState(0);
  const [loginviews, setloginviews] = React.useState(0);
  const [eventsviews, seteventsviews] = React.useState(0);
  const [flagshipviews, setflagshipviews] = React.useState(0);
  const [signedinuserscount, setsignedinuserscount] = React.useState(0);
  const [pie1, setpie1] = React.useState(0);
  const [pie2, setpie2] = React.useState(0);
  const [pie3, setpie3] = React.useState(0);
  const [pie4, setpie4] = React.useState(0);

  function calculatepievalue() {
    let totalpie =
      homeviews +
      loginviews +
      eventsviews +
      flagshipviews +
      signedinuserscount +
      eventsviews +
      flagshipviews;

    setpie1(
      Math.floor(
        ((homeviews + loginviews + eventsviews + flagshipviews) / totalpie) *
        100
      )
    );
    setpie2(Math.floor((signedinuserscount / totalpie) * 100));
    setpie3(Math.floor((eventsviews / totalpie) * 100));
    setpie4(Math.floor((flagshipviews / totalpie) * 100));
  }
  React.useEffect(() => {
    axios
      .get(`${url}/getanalytics`)
      .then((res) => {
        sethomeviews(
          JSON.parse(
            cryptojs.AES.decrypt(res.data.docs, "kowshik123").toString(
              cryptojs.enc.Utf8
            )
          )[0].home
        );
        setloginviews(
          JSON.parse(
            cryptojs.AES.decrypt(res.data.docs, "kowshik123").toString(
              cryptojs.enc.Utf8
            )
          )[0].login
        );
        seteventsviews(
          JSON.parse(
            cryptojs.AES.decrypt(res.data.docs, "kowshik123").toString(
              cryptojs.enc.Utf8
            )
          )[0].events
        );
        setflagshipviews(
          JSON.parse(
            cryptojs.AES.decrypt(res.data.docs, "kowshik123").toString(
              cryptojs.enc.Utf8
            )
          )[0].flagship
        );
      })
      .catch((err) => {
        // // console.log(err);
      });
    axios
      .get(`${url}/signedinusercount`)
      .then((res) => {
        setsignedinuserscount(res.data.docs);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  React.useEffect(() => {
    if (signedinuserscount > 0 && homeviews > 0) calculatepievalue();
  }, [signedinuserscount]);
  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#ffff",
            padding: "5px",
            border: "1px solid #cccc",
          }}
        >
          <label>{`${payload[0].name} : ${payload[0].value}%`}</label>
        </div>
      );
    }
    return null;
  };
  const COLORS = ["#8884d8", "#82ca9d", "#FFBB28", "#FF8042", "#AF19FF"];
  const piedata = [
    { name: "Total Visitors", value: pie1 },
    { name: "SignedIn Users", value: pie2 },
    { name: "Events Visitors", value: pie3 },
    { name: "Flagship Visitors", value: pie4 },
  ];
  const data = [
    { name: "HOME", uv: homeviews, pv: 2400, amt: 2400 },
    { name: "LOGIN", uv: loginviews, pv: 2600, amt: 2600 },
    { name: "EVENTS", uv: eventsviews, pv: 2800, amt: 2800 },
    { name: "FLAGSHIP", uv: flagshipviews, pv: 3000, amt: 3000 },
  ];
  return (
    <div>
      <div
        style={{ height: "fit-content" }}
        className=" w-100 d-flex flex-column lg:flex-row align-items-center justify-content-center lg:pl-20"
      >
        <div className="">
          <LineChart
            width={600}
            height={300}
            data={data}
            margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
          >
            <Line type="monotone" dataKey="uv" stroke="#8884d8" />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
          </LineChart>
        </div>
        <br />
        <a
          className="btn btn-primary justify"
          href="https://analytics.google.com/"
          target="_blank"
        >
          Get More Analytics
        </a>
        <div className="d-flex  lg:flex-row gap-2 flex-col justify-content-evenly my-5 lg:w-100 w-full">
          <div
            className="p-3 lg:w-1/3 border-[1px] border-blue-500"
            style={{
              // boxShadow: "0 0 5px grey",
              borderRadius: "10px",
            }}
          >
            <h5 className="text-primary">Website Viewers</h5>
            <p className="text-muted">Approx - Main Page visitors</p>
            <b className="text-danger h2">
              {homeviews + loginviews + eventsviews + flagshipviews}
            </b>
          </div>
          <br />
          <div
            className="p-3 lg:w-1/3 border-[1px] border-blue-500"
            style={{
              // boxShadow: "0 0 5px grey",
              borderRadius: "10px",
            }}
          >
            <h5 className="text-primary">Flagship Pitches</h5>
            <p className="text-muted" style={{ fontSize: "12px" }}>
              From Day 1
            </p>
            <b className="text-danger h2">{pitches.length}</b>
          </div>
          <br />
          <div
            className="p-3 lg:w-1/3 border-[1px] border-blue-500"
            style={{
              // boxShadow: "0 0 5px grey",
              borderRadius: "10px",
            }}
          >
            <h5 className="text-primary">Total Events Created</h5>
            <p className="text-muted" style={{ fontSize: "12px" }}>
              General + LEED
            </p>
            <b className="text-danger h2">
              {verifiedLEEDevents.length + verifiedevents.length}
            </b>
          </div>
        </div>

        <PieChart width={730} height={300}>
          <Pie
            data={piedata}
            color="#000000"
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={120}
            fill="#8884d8"
          >
            {piedata.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
          <Legend />
        </PieChart>
      </div>
    </div>
  );
}

export default Dashboard;
