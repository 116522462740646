import React, { useEffect } from "react";
import "./aboutus.css";
import "./home.css";
import LEEDimglogo from "../Assets/Leed-Logo-01 (2).png";
import image1 from "../Assets/finspiration.jpg";
import image2 from "../Assets/jugaad.jpg";
import image3 from "../Assets/sharktank.jpg";
import calendarpic from "../Assets/achievement.png";
import calendarpic1 from "../Assets/calendar.png";
import celebrity from "../Assets/cheran.jpeg";
import cardimage3 from "../Assets/business-concept-with-team-close-up.jpg";
import hariimg from "../Assets/hari leed.jpg";
import kiranimg from "../Assets/kiran leed.jpg";
import Footer from "../components/footer";
import kctimage from "../Assets/Kctimage.jpeg";
import { BsStar, BsStarFill } from "react-icons/bs";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import {
  BsEnvelopeAtFill,
  BsEnvelopeFill,
  BsEnvelopeOpen,
  BsLinkedin,
} from "react-icons/bs";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { BsMailbox } from "react-icons/bs";
import { BsMailbox2 } from "react-icons/bs";
import { TbMessage, TbMessage2, TbMessage2Bolt } from "react-icons/tb";
function AboutUs() {
  const newGoals = [
    "LEED: Empowering students to become visionary entrepreneurs through collaboration and expert mentorship.",
    "LEED turns dreams into reality, sparking innovation and guiding students to transform ideas into tangible ventures.",
    "LEED is a knowledge powerhouse, equipping students with skills, insights, and tools to navigate the entrepreneurial landscape for a brighter future.",
  ];
  const whatWeDoContent = [
    "We foster the growth of student startups by providing a nurturing environment that encourages their development. Through our incubation program, we empower young entrepreneurs to bring their ideas to life and flourish in the competitive business world.",
    "Our mission is to fuel the potential of student-led startups. We offer a comprehensive array of resources, from mentorship to infrastructure, ensuring that these ventures have the fuel they need to thrive and make a lasting impact.",
    "We empower the dreams of budding entrepreneurs. By offering essential resources and a supportive ecosystem, we help student startups transform their concepts into reality, setting them on a path to success.",
  ];

  return (
    <div className="w-screen mt-20">
      {/* <img className="" id='parallax' src={kctimage} alt="kct image" /> */}
      <div className="h-screen sm:h-auto flex flex-col items-center justify-center">
        {/* <img className="" src={LEEDimglogo} alt="" /> */}
        <h2 className="font-bold italic text-center text-blue-500 px-4 md:pt-20">
          LEAGUE OF ENTREPRENEURIAL EMPOWERMENT AND DEVELOPMENT
        </h2>
        <h4 className=" font-bold text-lg italic text-center text-blue-600">
          ~ Since 2013
        </h4>
      </div>
      {/* <div className='flex flex-col min-h-screen max-h-fit items-center justify-center'>
        <h2 className='text-yellow-400 text-5xl font-semibold'>Who Are We.</h2>
        <ul className=''>
          <li> LEED stands as an empowering platform where aspiring students morph into tomorrow's visionary entrepreneurs. Through a collaborative ecosystem, they breathe life into their business dreams, backed by expert mentorship.</li>
          <li> At LEED, dreams cease to be just thoughts. Here, they're woven into reality. This forum ignites the spark of innovation and guides students to transform their creative business ideas into tangible ventures.</li>
          <li>LEED isn't just a platform; it's a knowledge powerhouse. It equips students with the skills, insights, and tools needed to navigate the entrepreneurial landscape, empowering them to build a brighter future.</li>
        </ul>
      </div> */}
      <div className="w-full h-fit lg:h-screen ex flex-col items-center justify-center snap-start pt-20">
        <h2 className="text-4xl sm:text-5xl mx-auto sm:w-3/4 text-center font-semibold  text-blue-500">
          <span className="block mx-auto mt-3  font-semibold  text-teal-400 text-2xl sm:text-4xl drop-shadow-2xl">
            Who Are We
          </span>
          Know About Us from the start
        </h2>
        <div className="flex flex-col lg:flex-row gap-10 items-center py-20 justify-evenly w-full h-fit sm:py-15 sm:px-5 lg:px-20">
          <div className="flex flex-col md:px-10 px-10 lg:pl-20  items-start justify-center w-full ">
            {newGoals.map((goal) => (
              <p className="text-blue-500 text-xl">
                <BsFillCheckCircleFill
                  className="inline text-blue-500 mr-5"
                  size={20}
                />
                {goal}
              </p>
            ))}
          </div>
          {/* <img
            src={goal}
            className="w-3/4 lg:w-1/4 shadow-blue-400 rounded-3xl shadow-2xl"
            alt="kct event image"
          /> */}
        </div>
      </div>
      <div className="bg-slate-50 pt-20">
        <VerticalTimeline lineColor="#266ee7" className="text-blue-600">
          {/* 2021 */}
          <VerticalTimelineElement
            className="vertical-timeline-element--work rounded-3xl"
            date="2021"
            iconStyle={{
              background: "linear-gradient(to top right, #3182CE, #93C5FD)",
              color: "#fff",
            }}
          >
            <div className="flex items-center  justify-center sm:flex-row  lg:flex-col">
              <div>
                <h3 className="sm:vertical-timeline-element-title text-center font-semibold text-blue-500 mb-2 sm:flex-row">
                  JUGAAD{" "}
                </h3>
                <img
                  src={image2}
                  className="rounded-3xl shadow-xl"
                  style={{ width: 300, maxWidth: "100%", objectFit: "contain" }}
                  alt=""
                />
              </div>
              <p className="px-10 text-blue-500 w-3/4">
                The event is all about creating a new innovative product from
                two different existing products
              </p>
            </div>
          </VerticalTimelineElement>

          {/* 2022 */}
          <VerticalTimelineElement
            className="vertical-timeline-element--work rounded-3xl"
            date="2022"
            iconStyle={{
              background: "linear-gradient(to top right, #3182CE, #93C5FD)",
              color: "#fff",
            }}
          >
            <div className="flex items-center  justify-center sm:flex-row  lg:flex-col">
              <div>
                <h3 className="sm:vertical-timeline-element-title text-center font-semibold text-blue-500 mb-2 sm:flex-row">
                  Shark Tank{" "}
                </h3>
                <img
                  src={image3}
                  className="rounded-3xl shadow-xl"
                  style={{ width: 300, maxWidth: "100%", objectFit: "contain" }}
                  alt=""
                />
              </div>
              <p className="px-10 text-blue-500 w-3/4">
                Shark Tank acts as a platform where the budding entrepreneurs
                pitch their business model to venture capitalists
              </p>
            </div>
          </VerticalTimelineElement>

          {/* 2023 */}
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2023"
            iconStyle={{
              background: "linear-gradient(to top right, #3182CE, #93C5FD)",
              color: "#fff",
            }}
          >
            <div className="flex items-center  justify-center sm:flex-row  lg:flex-col">
              <div>
                <h3 className="sm:vertical-timeline-element-title text-center font-semibold text-blue-500 mb-2 sm:flex-row">
                  FINSPIRATION
                </h3>
                <img
                  src={image1}
                  className="rounded-3xl shadow-xl"
                  style={{ width: 300, maxWidth: "100%", objectFit: "contain" }}
                  alt=""
                />
              </div>
              <p className="px-10 text-blue-500 w-3/4">
                The event is to improve the sales and market standard of a
                business
              </p>
            </div>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
      <div className="w-full h-fit lg:h-screen ex flex-col items-center justify-center snap-start pt-20">
        <h2 className="text-4xl sm:text-5xl mx-auto sm:w-3/4 text-center font-semibold  text-blue-500">
          What We Do
        </h2>
        <div className="flex flex-col lg:flex-row gap-10 items-center py-20 justify-evenly w-full h-fit">
          <div className="flex flex-col md:px-10 px-10 lg:pl-20 items-start justify-center w-full ">
            {whatWeDoContent.map((goal) => (
              <p className="text-blue-500 text-xl">
                <BsFillCheckCircleFill
                  className="inline text-blue-500 mr-5"
                  size={20}
                />
                {goal}
              </p>
            ))}
          </div>
          {/* <img
            src={goal}
            className="w-3/4 lg:w-1/4 shadow-blue-400 rounded-3xl shadow-2xl"
            alt="kct event image"
          /> */}
        </div>
      </div>

      <div className="flex  flex-col items-center min-h-screen max-h-fit w-full px-2 py-6">
        <h2 className="text-blue-600 font-semibold">STUDENT COORDINATORS</h2>
        <p className="text-yellow-400 font-semibold font-handwritten text-3xl">
          LEED - 2023
        </p>
        <div className="w-full md:h-full flex lg:flex-row gap-10  justify-items-stretch justify-center flex-col">
          <div className="border shadow-xl overflow-hidden mx-2  h-3/4 md:h-72 sm:w-6/4 lg:w-[40%]  flex flex-col md:flex-row rounded-3xl">
            <div className="w-full md:w-2/5 p-4 flex-col md:flex-row lg:flex-row">
              <img
                src={kiranimg}
                className="w-full rounded-3xl object-cover md:h-full h-60"
                alt=""
              />
            </div>
            <div className="flex w-full md:w-3/4 h-full lg:flex-col sm:flex-row justify-between   relative text-center  items-start  p-4">
              <p className="bg-blue-500  absolute top-5 left-5 px-4 text-lg py-1 rounded-3xl text-white">
                President
              </p>
              <div className="flex flex-col mt-4 justify-center h-full items-start">
                <h5 className="text-blue-600 text-4xl text-left lg:mt-0 mt-14">
                  Kiran Srinivas
                </h5>
                <p className="text-gray-600 text-lg">B.E final year in EEE</p>
                <p className="text-lg leading-3">2023-24</p>
                <div className="flex flow-row gap-4 items-center justify-center">
                  <a target="_blank" href="https://www.linkedin.com/in/kiran-srinivas-s-99a5b3184">
                    <BsLinkedin className="text-blue-500" size={50} />
                  </a>
                  <a href="mailto:kiransrinivas.20ee@kct.ac.in">
                    <BsEnvelopeFill className="text-blue-500" size={60} />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="border shadow-xl h-3/4 md:h-72 overflow-hidden mx-2 sm:w-6/4 lg:w-[40%]  flex flex-col md:flex-row rounded-3xl">
            <div className="w-full md:w-2/5 p-4 flex-col md:flex-row lg:flex-row">
              <img
                src={hariimg}
                className="w-full rounded-3xl object-cover md:h-full h-60"
                alt=""
              />
            </div>
            <div className="flex w-full md:w-3/4 h-full lg:flex-col sm:flex-row justify-between  relative text-center  items-start  p-4">
              <p className="bg-blue-500  absolute top-5 left-5 px-4 text-lg py-1 rounded-3xl text-white">
                Former President
              </p>
              <div className="flex flex-col mt-4 justify-center h-full  items-start">
                <h5 className="text-blue-600 text-left text-4xl lg:mt-0 mt-14">
                  Hariharan
                </h5>
                <p className="text-gray-600 text-lg">B.E final year in EIE</p>
                <p className="text-lg leading-3">2022-23</p>
                <div className="flex  flow-row gap-4 items-center justify-center">
                  {/* <a href=""> */}
                  <BsLinkedin className="text-blue-500" size={50} />
                  {/* </a> */}
                  {/* <a href="mailto:"> */}
                  <BsEnvelopeFill className="text-blue-500" size={60} />
                  {/* </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border mb-10 mt-10 shadow-xl  md:mx-auto overflow-hidden   h-3/4 md:h-72 w-full lg:w-1/2  flex flex-col md:flex-row rounded-3xl">
          <div className="w-full md:w-2/5 p-4 flex-col md:flex-row lg:flex-row">
            <img
              src="/members/haribabu.jpeg"
              className="w-full rounded-3xl object-cover md:h-full h-60"
              alt="Hari babu - Head of LEED"
            />
          </div>
          <div className="flex w-full md:w-3/4 lg:flex-col sm:flex-row justify-between  relative text-center  items-start  p-4">
            <p className="bg-blue-500  absolute top-5 left-5 px-4 text-lg py-1 rounded-3xl text-white">
              Staff coordinator
            </p>
            <div className="flex flex-col mt-4 justify-center h-full items-start">
              <h5 className="text-blue-600 text-left text-4xl lg:mt-0 my-10">
                Dr. Hari Baabu V
              </h5>
              {/* <p className="text-gray-600 text-lg">B.E final year in EEE</p> */}
              {/* <p className="text-lg leading-3">2022-23</p> */}
              <div className="flex flow-row gap-4 items-center justify-center">
                <a target="_blank" href="https://www.linkedin.com/in/haribaabu">
                  <BsLinkedin className="text-blue-500" size={50} />
                </a>
                <a href="mailto:haribaabu.v@kct.ac.in">
                  <BsEnvelopeFill className="text-blue-500" size={60} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h3 className="text-5xl w-full bg-gradient-to-r from-blue-600 to-teal-400 bg-clip-text text-transparent text-center font-semibold mt-20  ">
        Testimonial
      </h3>
      <div className="w-full md:h-full py-28 md:py-18 md:px-0 px-3">
        <div className="lg:w-1/3 sm:w-2/3 md:h-full  border-[1px] border-blue-50 pt-10 md:pt-14 py-6 pb-12 relative bottom-20 mx-auto rounded-3xl flex flex-col justify-center items-center bg-gradient-to-tr from-blue-500 to-blue-300 shadow-2xl">
          <p className="bg-white  absolute top-5 left-5  text-black px-3 py-1 rounded-3xl">
            Review
          </p>
          <img
            src={celebrity}
            className="h-52 w-52 bg-white shadow-2xl rounded-full object-contain"
            alt=""
          />
          <div className="w-full flex flex-col items-center justify-center px-10  mt-3">
            <b className="text-white text-2xl">HUSSAIN AHMED </b>
            <p className="text-md bg-white/70 px-3 py-1 rounded-3xl text-blue-500 mt-1">
              CEO OF CHERAN ACADEMY
            </p>
            <q className="text-md text-white text-center">
              LEED is a good platform for budding entrepreneurs
            </q>
            <div className="flex mt-4 w-full flex-row justify-center items-center gap-2">
              <BsStarFill className="text-yellow-300" size={30} />
              <BsStarFill className="text-yellow-300" size={30} />
              <BsStarFill className="text-yellow-300" size={30} />
              <BsStarFill className="text-yellow-300" size={30} />
              <BsStarFill className="text-yellow-300" size={30} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AboutUs;
