import React, { useState } from "react";
import "./ST.css";
import axios from "axios";
import { Routes, Route, Link, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { BsPlusCircleFill } from "react-icons/bs";
import { setyourpitch } from "../Controllers/redux";
import { bgcolor } from "@mui/system";
import {
  TbLayoutSidebarRightCollapseFilled,
  TbLayoutSidebarLeftCollapseFilled,
} from "react-icons/tb";
import HomeST from "../Models/SharkTank/homeST";
const cryptojs = require("crypto-js");

function ST() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const url = useSelector((state) => state.url.value);
  const [userName, setuserName] = React.useState("");
  const [Email, setEmail] = React.useState("");
  const [userProfilePic, setUserProfilePic] = React.useState("");
  const [userid, setuserid] = React.useState("");
  const [renderevents, setrenderevents] = React.useState("All Events");
  const [showHelp, setShowHelp] = React.useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [showCurrentUserPitch, setShowCurrentUserPitch] = useState(false);
  React.useEffect(() => {
    if (
      sessionStorage.getItem("email") !== null &&
      sessionStorage.getItem("email") !== undefined
    ) {
      var bytes = cryptojs.AES.decrypt(
        sessionStorage.getItem("name"),
        "kowshik123"
      );
      setuserName(() => bytes.toString(cryptojs.enc.Utf8));
      var bytesemail = cryptojs.AES.decrypt(
        sessionStorage.getItem("email"),
        "kowshik123"
      );
      setEmail(() => bytesemail.toString(cryptojs.enc.Utf8));
      var bytesimage = cryptojs.AES.decrypt(
        JSON.parse(sessionStorage.getItem("pic")),
        "kowshik123"
      );
      setUserProfilePic(() => bytesimage.toString(cryptojs.enc.Utf8));
      var bytesuserid = cryptojs.AES.decrypt(
        JSON.parse(sessionStorage.getItem("userid")),
        "kowshik123"
      );
      setuserid(() => bytesuserid.toString(cryptojs.enc.Utf8));
    }
  }, []);
  const [ActiveMenu, setActiveMenu] = React.useState("allevents");
  React.useEffect(() => {
    if (userid) {
      axios
        .post(`${url}/yourpitchST`, {
          id: userid,
        })
        .then((res) =>
          dispatch(
            setyourpitch(
              JSON.parse(
                cryptojs.AES.decrypt(res.data.docs, "kowshik123").toString(
                  cryptojs.enc.Utf8
                )
              )
            )
          )
        );
    }
  });

  const choosepicker = (msg) => {
    setrenderevents(msg);
  };
  const helpdesk = (msg) => {
    setShowHelp(msg);
  };

  return (
    <div className="w-screen min-h-screen flex flex-row ">
      <div className="flex w-full relative flex-col justify-end">
        {/* <PitchesSidebar choosepicker={() => { }} helpdesk={() => { }} /> */}
        <div className="w-1/5  z-1 lg:flex-row flex fixed left-0 top-0 py-10 lg:pt-10 pt-20 h-screen ">
          {/* <SidebarEvent choosepicker={choosepicker} helpdesk={helpdeskfunc} /> */}
          <button
            onClick={() => {
              setIsHidden(true);
            }}
            className="lg:hidden block absolute"
          >
            <TbLayoutSidebarRightCollapseFilled color="blue" size={50} />
          </button>
          {/* sidebar */}

          <div
            className={`w-full z-1 top-0 min-h-screen  lg:hidden block bg-gradient-to-l from-blue-500 to-teal-500 ${isHidden == false ? "-left-full" : "left-0"
              }`}
          >
            <button
              onClick={() => {
                setIsHidden((prev) => !prev);
              }}
              className="text-4xl lg:hidden block pt-20"
            >
              <TbLayoutSidebarLeftCollapseFilled color="white" size={50} />
            </button>
            <div className="lg:pl-96 pl-20 justify-evenly">
              <NavLink
                onClick={(e) => {
                  choosepicker("All Events");
                  setActiveMenu("allevents");
                }}
              >
                <p
                  icon="columns"
                  className={
                    ActiveMenu == "allevents"
                      ? " text-white no-underline  px-4 py-2"
                      : "text-white no-underline  px-4 py-2"
                  }
                >
                  All Pitches
                </p>
              </NavLink>
              <NavLink
                className={
                  ActiveMenu == "todayevents"
                    ? "text-white no-underline px-4 py-2 "
                    : " text-white no-underline  px-4 py-2 "
                }
                onClick={() => {
                  choosepicker("Today Events");
                  setActiveMenu("todayevents");
                }}
              >
                <p className="pl-6">Your Pitches</p>
              </NavLink>
              <div className="pt-80 pr-1">
                <div
                  className={
                    bgcolor == undefined
                      ? ActiveMenu == "helpdesk"
                        ? "text-warning sidebar-btn-wrapper"
                        : "text-light sidebar-btn-wrapper"
                      : "text-white"
                  }
                  style={{
                    padding: "20px 5px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setActiveMenu("helpdesk");
                    helpdesk(true);
                  }}
                >
                  <p className="text-2xl  bg-white/30 w-fit relative mx-auto px-2 py-2 rounded-xl">
                    Create Pitch{" "}
                    <BsPlusCircleFill
                      size={35}
                      className="text-white ml-5 inline"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`w-full z-1 min-h-screen relative justify-evenly lg:block hidden pt-40 bg-gradient-to-l from-blue-500 to-teal-500`}
          >
            <button
              onClick={() => {
                setIsHidden((prev) => !prev);
              }}
              className="text-4xl lg:hidden block pt-20"
            >
              <TbLayoutSidebarLeftCollapseFilled color="white" size={50} />
            </button>
            <div className="lg:pl-16 pl-20 justify-evenly">
              <NavLink
                className={
                  ActiveMenu == "todayevents"
                    ? "text-white no-underline px-4 py-2 "
                    : " text-white no-underline  px-4 py-2 "
                }
                onClick={(e) => {
                  setShowCurrentUserPitch(false);
                }}
              >
                <p className="pl-6">All Pitches</p>
              </NavLink>
              <NavLink
                className={
                  ActiveMenu == "todayevents"
                    ? "text-white no-underline px-4 py-2 "
                    : " text-white no-underline  px-4 py-2 "
                }
                onClick={() => {
                  setShowCurrentUserPitch(true);
                }}
              >
                <p className="pl-6">Your Pitches</p>
              </NavLink>
              <a className="text-sm   bg-white/30 w-full relative mx-auto px-2 py-2 rounded-xl no-underline text-white" href="/flagship/createpitchST">
                Create Pitch{""}
                <BsPlusCircleFill
                  size={25}
                  className="text-white ml-5 inline "
                />
              </a>
            </div>
          </div>
        </div>
        <Outlet />
        <HomeST showCurrentUserPitch={showCurrentUserPitch} userMail={Email} />
      </div>
      {/* <Footer /> */}
    </div >
  );
}

export default ST;
