import React from "react";
import student from "../Assets/heroimage.jpg";
import { TbTargetArrow } from "react-icons/tb";
import { TbBulb } from "react-icons/tb";
import { FaHandsHelping } from "react-icons/fa";
import { motion } from "framer-motion";
const Hero = () => {
  const objectives = [
    {
      name: " Foster idea sharing among budding entrepreneurs",
      icon: <TbBulb size={60} />,
    },
    {
      name: "Elevate entrepreneurial standards through targeted initiatives",
      icon: <TbTargetArrow size={60} />,
    },
    {
      name: "Provide essential resources for merging business leaders",
      icon: <FaHandsHelping size={60} />,
    },
  ];
  return (
    <div className="relative shadow-white shadow-2xl pt-20  bg-gradient-to-tr from-teal-400 to-blue-600 px-3 md:px-20 flex flex-col lg:flex-row  justify-between lg:justify-evenly items-center   text-white w-screen h-fit py-20 lg:pb-40 gap-10 z-10 ">
      <motion.div
        initial={{ x: -160 }}
        whileInView={{ x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 1.5 }}
        className="flex w-fit   flex-col h-3/4 items-center lg:items-start justify-center  lg:w-1/3"
      >
        <h1 className="text-9xl lg:hidden block w-full text-center sm:text-left font-bold bg-gradient-to-tr italic from-white/30 to-white bg-clip-text text-transparent drop-shadow-2xl">
          LEED
        </h1>
        <h1 className="text-9xl lg:block hidden w-full text-left sm:text-left font-bold bg-gradient-to-tr italic from-white/30 to-white bg-clip-text text-transparent drop-shadow-2xl">
          LEED
        </h1>
        <p className="text-3xl w-full lg:hidden block text-center sm:text-left ">
          <span className="inline from-yellow-700 to-yellow-300 bg-gradient-to-tl bg-clip-text text-transparent font-extrabold text-4xl">
            L
          </span>
          eague of <br className="lg:flex hidden" />
          <span className="inline from-yellow-700 to-yellow-300 bg-gradient-to-tl bg-clip-text text-transparent font-extrabold text-4xl">
            E
          </span>
          ntrepreneurial <br className="lg:flex hidden" />
          {/* <br /> */}
          <span className="inline from-yellow-700 to-yellow-300 bg-gradient-to-tl bg-clip-text text-transparent font-extrabold text-4xl">
            E
          </span>
          mpowerment and <br className="lg:flex hidden" />
          <span className="inline from-yellow-700 to-yellow-300 bg-gradient-to-tl bg-clip-text text-transparent font-extrabold text-4xl">
            D
          </span>
          evelopment
        </p>
        <p className="text-3xl w-full lg:block hidden text-left sm:text-left ">
          <span className="inline from-yellow-700 to-yellow-300 bg-gradient-to-tl bg-clip-text text-transparent font-extrabold text-4xl">
            L
          </span>
          eague of <br className="lg:flex hidden" />
          <span className="inline from-yellow-700 to-yellow-300 bg-gradient-to-tl bg-clip-text text-transparent font-extrabold text-4xl">
            E
          </span>
          ntrepreneurial <br className="lg:flex hidden" />
          {/* <br /> */}
          <span className="inline from-yellow-700 to-yellow-300 bg-gradient-to-tl bg-clip-text text-transparent font-extrabold text-4xl">
            E
          </span>
          mpowerment and <br className="lg:flex hidden" />
          <span className="inline from-yellow-700 to-yellow-300 bg-gradient-to-tl bg-clip-text text-transparent font-extrabold text-4xl">
            D
          </span>
          evelopment
        </p>
        <p className="text-xl sm:text-center lg:text-left md:text-xl font-bold italic text-slate-100  mt-2 w-full ">
          "LEED is a dynamic platform where visionary minds converge, fostering
          entrepreneurship and economic growth."
        </p>
      </motion.div>
      <motion.div
        initial={{ x: 160 }}
        whileInView={{ x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 1.5 }}
        className="w-full md:w-3/4 mb-20 lg:w-1/3  lg:h-1/2 rounded-xl "
      >
        <img
          src={student}
          className="h-full mx-auto w-4/5 lg:w-fit object-contain rounded-sm"
          alt="student image"
        />
      </motion.div>
      <div className="flex flex-col  md:px-20 lg:px-0 lg:flex-row w-full lg:left-1/2 lg:-translate-x-1/2 z-10 gap-5 lg:gap-20 lg:absolute mx-auto lg:-bottom-24 lg:mt-0 mt-20 items-stretch justify-center">
        {objectives.map((obj, index) => (
          <motion.div
            initial={{ y: 160 }}
            whileInView={{ y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 1, delay: (index + 1) * 0.1 }}
            className="py-4 border-teal-400 border-r-8 lg:py-10 gap-5 lg:gap-10 w-[90%] mx-auto -skew-x-12 skew-y-12 lg:w-1/4 text-white font-thin flex flex-col  lg:flex-row justify-evenly items-center px-10 bg-blue-600 shadow-2xl  text-xl text-left"
          >
            {obj.icon}
            <p className="w-full  text-md text-left italic font-semibold md:text-left ">
              {obj.name}
            </p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Hero;
