import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  TbSquareRoundedArrowLeft,
  TbSquareRoundedArrowLeftFilled,
  TbSquareRoundedArrowRight,
} from "react-icons/tb";

const PichtesCarousel = (props) => {
  const [Email, setEmail] = React.useState("");
  const navigate = useNavigate();
  const experts = useSelector((state) => state.experts.value);
  const cryptojs = require("crypto-js");

  useEffect(() => {
    if (sessionStorage.getItem("email")) {
      const email = cryptojs.AES.decrypt(
        sessionStorage.getItem("email"),
        "kowshik123"
      );

      setEmail(email);
      // // console.log("---------------------");
      // // console.log(email);
      // // console.log(sessionStorage.getItem("email"));
    }
  }, []);

  // // Add the following code to automatically scroll the carousel
  // const [isScrolling, setIsScrolling] = useState(false);
  // const [scrollInterval, setScrollInterval] = useState(1000); // 1 second

  // useEffect(() => {
  //   if (isScrolling) {
  //     const scrollFunc = () => {
  //       const carousel = document.getElementById("scrolling");
  //       carousel.scrollLeft += 10;

  //       if (carousel.scrollLeft === carousel.scrollWidth) {
  //         carousel.scrollLeft = 0;
  //       }
  //     };

  //     const interval = setInterval(scrollFunc, scrollInterval);

  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }
  // }, [isScrolling, scrollInterval]);

  // // Start scrolling when the component mounts
  // useEffect(() => {
  //   setIsScrolling(true);
  // }, []);

  // // Stop scrolling when the component unmounts
  // useEffect(() => {
  //   return () => {
  //     setIsScrolling(false);
  //   };
  // }, []);

  // // Add a button to toggle scrolling
  // const toggleScrolling = () => {
  //   setIsScrolling(!isScrolling);
  // // };

  // useEffect(() => {
  //   document.getElementById("scrolling").addEventListener("scroll", () => {
  //     // // console.log("scrollingjknk");
  //   });
  // });

  function scrollCarousel(x) {
    // // // console.log("scrolling")
    document
      .getElementById("scrolling")
      .scrollBy({ left: 300 * x, behavior: "smooth" });
  }
  return (
    <div className="w-screen pt-40  overflow-x-hidden no-scrollbar">
      <h2 className="text-5xl w-full text-center text-blue-500">Flagships</h2>
      <p
        className="text-center cursor-pointer"
        // onClick={() => {
        //   // // // console.log("scrolling")
        //   document
        //     .getElementById("scrolling")
        //     .scrollBy({ left: 300, behavior: "smooth" });
        // }}
      >
        Find the list of trending pitches from the students of our oragnisation.
      </p>
      <div className="w-screen   flex flex-col md:flex-row items-center justify-center">
        <div
          id="scrolling"
          className="w-[100%]  no-scrollbar overflow-x-scroll snap-x snap-mandatory h-fit pt-10 pb-20"
        >
          <div className=" md:px-10 lg:px-20 snap-end min-w-min flex flex-row gap-24">
            {props.filteredPitches.map((val, ind) => (
              // <div className=" px-3 md:px-0  w-screen">
              <div
                key={val.title}
                className="w-[90vw]  lg:w-[23vw]  shadow-2xl mx-auto snap-start rounded-3xl relative overflow-hidden h-[60vh] flex flex-col bg-blue-500"
                onClick={() =>
                  navigate(
                    Email &&
                      (Email === experts.expert1 ||
                        Email === experts.expert2 ||
                        Email === experts.expert3)
                      ? "flagshipexpert"
                      : sessionStorage.getItem("email") &&
                        cryptojs.AES.decrypt(
                          sessionStorage.getItem("email"),
                          "kowshik123"
                        ).length > 0
                      ? "flagship"
                      : "/login"
                  )
                }
              >
                <div className="w-full bg-slate-400 h-1/2">
                  <img
                    className="h-full w-full object-cover"
                    src={val.image[0]["secure_url"]}
                    alt=""
                  />
                </div>
                <div className="h-1/2 flex flex-col items-start  justify-between py-3  bg-gradient-to-tr from-blue-600 to-teal-400 w-full text-white px-10">
                  <p className="text-2xl md:text-4xl lg:text-xl">{val.title}</p>
                  <div className="w-full flex gap-6 lg:gap-1 flex-row items-stretch justify-start">
                    <button className="border-white border-[1px]  px-2 py-2 rounded-xl ">
                      more details
                    </button>
                    <button className="bg-white  text-blue-600  px-2 py-2 rounded-xl ">
                      Collaborate
                    </button>
                  </div>
                </div>
              </div>
              // </div>
            ))}
            {/* {props.filteredPitches.map((val, ind) => (
              // <div className=" px-3 md:px-0  w-screen">
              <div
                key={val.title}
                className="  lg:w-[23vw]  shadow-2xl mx-auto snap-center rounded-3xl relative overflow-hidden h-[50vh] flex flex-col bg-blue-500"
                onClick={() =>
                  navigate(
                    Email === experts.expert1 ||
                      Email === experts.expert2 ||
                      Email === experts.expert3
                      ? "flagshipexpert"
                      : Email.length > 0
                      ? "flagship"
                      : "/login"
                  )
                }
              >
                <div className="w-full bg-white h-1/2">
                  <img
                    className="h-full w-full object-cover"
                    src={val.image[0]["secure_url"]}
                    alt=""
                  />
                </div>
                <div className="h-1/2 flex flex-col items-start  justify-between py-3  bg-gradient-to-tr from-blue-600 to-blue-400 w-full text-white px-10">
                  <p className="text-2xl md:text-4xl lg:text-xl">{val.title}</p>
                  <div className="w-full flex gap-6 lg:gap-1 flex-row items-stretch justify-start">
                    <button className="border-white border-[1px]  px-2 py-2 rounded-xl ">
                      more details
                    </button>
                    <button className="bg-white  text-blue-600  px-2 py-2 rounded-xl ">
                      Collaborate
                    </button>
                  </div>
                </div>
              </div>
              // </div>
            ))} */}
          </div>
        </div>
        <div className="w-1/2 md:w-[10%] flex flex-row md:flex-col items-center justify-center h-full  ">
          <TbSquareRoundedArrowLeftFilled
            onClick={() => scrollCarousel(-1)}
            className="cursor-pointer text-blue-500"
            size={60}
          />
          <TbSquareRoundedArrowRight
            onClick={() => scrollCarousel(1)}
            className="cursor-pointer text-blue-500"
            size={60}
          />
        </div>
      </div>
    </div>
  );
};

export default PichtesCarousel;
