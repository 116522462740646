import React from "react";
import { motion } from "framer-motion";
const StudentEngageMent = () => {
  const documents = [
    {
      number: 5,
      description: "Business Conference Participated",
    },
    {
      number: 75,
      description: "LEED Alumni",
    },
    {
      number: 1000,
      description: "People Impacted",
    },
    {
      number: 46,
      description: "Events Conducted",
    },
    {
      number: 32,
      description: "Expert Talks Hosted",
    },
  ];

  return (
    <div className="w-screen h-fit lg:min-h-screen max-h-fit bg-gradient-to-tr from-teal-400 to-blue-600 py-20 text-white ">
      <h2 className="text-5xl w-full text-center font-extrabold text-white">
        Students Engagement
      </h2>

      <div className="w-full h-3/4 lg:gap-10 gap-5 md:gap-10  px-5 lg:px-20 flex flex-row flex-wrap items-stretch mt-14 justify-center">
        {documents.map((document, index) => (
          <motion.div
            initial={{ scale: 0.5, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{
              duration: 0.6,
              delay: (index + 1) * 0.05,
              ease: "easeIn",
            }}
            className="bg-white/30 w-full  md:w-1/3 lg:w-1/4 text-white px-8 py-4 shadow-3xl shadow-black rounded-3xl flex flex-col items-start justify-evenly"
          >
            <p className="text-center w-full text-2xl md:text-5xl font-extrabold">
              {document.number}+
            </p>
            <p className="text-center w-full text-md md:text-xl font-light">
              {document.description}
            </p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default StudentEngageMent;
