import React from 'react'
import logo2 from '../Assets/logo2.png'
import TextTransition, { presets } from 'react-text-transition';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import axios from 'axios';
import LazyLoad from 'react-lazy-load';
import 'pure-react-carousel/dist/react-carousel.es.css';
import kctImage from "../Assets/Kctimage.jpeg";
import './ImageHolderHome.css'
import '../Views/home.css';
import { useSelector } from 'react-redux'
import { createChainedFunction } from '@mui/material';
const cryptojs = require("crypto-js")
function ImageHolderHome() {
  const url = useSelector((state) => state.url.value)
  const [index, setIndex] = React.useState(0);
  const [YTvideos, setYTvideos] = React.useState([])
  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000, // every 3 seconds
    );
    return () => clearTimeout(intervalId);


  }, []);
  React.useEffect(() => {
    axios({
      method: "GET",
      url: `${url}/getYTReferral`,
    }).then((res) => {

      setYTvideos(JSON.parse(cryptojs.AES.decrypt(res.data.docs, 'kowshik123').toString(cryptojs.enc.Utf8)))
    }).catch((err) => {
      // // console.log(err)
    })
  }, [])
  return (
    <div className='text-center z-10  md:mt-16 lg:mt-0 h-full  md:h-fit  w-screen lg:h-screen overflow-visible '>
      <div className='flex bg-gradient-to-tr lg:py-0   flex-col md:py-16  relative items-center justify-center lg:justify-start w-full h-full'>
        <h1 className='text-[#b2d235]  w-fit mx-auto relative bottom-2 pb-2 lg:pb-3 rounded-lg lg:rounded-xl px-6 lg:px-16 bg-white bg-opacity-60  mt-44 lg:mt-28 text-6xl  drop-shadow-2xl font-extrabold lg:font-bold'>LEED
          <span className='text-slate-700 block absolute left-1/2 -translate-x-1/2  lg:bottom-[3px] lg:relative whitespace-nowrap  text-[6px] lg:text-xl font-bold'>KUMARAGURU INSTITUTIONS</span>
        </h1>
      </div>
    </div>
  )
}

export default ImageHolderHome
