import React, { useEffect } from 'react';
import './App.css';
import Login from './Views/login';
import NavInApp from './Views/nav';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'
import Requests from './Admin/Requests';
import axios from 'axios';
import Home from './Views/home';
import Events from './Views/events';
import Eventregister from './Views/eventregister';
import Comments from './Experts/Comments';
import Reports from './Experts/reports';
import LEEDevents from './Views/LEEDevents';
import "react-sweet-progress/lib/style.css";
import ST from './Views/ST';
import UserPitch from './Models/SharkTank/createpitch';
import HomeST from './Models/SharkTank/homeST';
import YourPitch from './Models/SharkTank/YourPitch';
import STexpert from './Experts/sharktankexpert';
import Pitches from './Models/SharkTank/pitches';
import { useDispatch, useSelector } from 'react-redux';
import { setGenEvents, setLEEDEvents } from './Controllers/redux';
import { setPitches } from './Controllers/redux';
import AboutUs from './Views/aboutus';
import Contact from './Views/contact';
import LoadingScreen from 'react-loading-screen'
import Member from './Views/members';
const cryptojs = require("crypto-js")


function App() {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const url = useSelector((state) => state.url.value)
  const loggingstatus = useSelector((state) => state.logging.value)
  const logoutstatus = useSelector((state) => state.logout.value)
  const creatingpitch = useSelector((state) => state.creatingpitch.value)
  const [ignored, forceUpdate] = React.useReducer(x => x + 1, 0)
  const [isLoading, setIsLoading] = React.useState(true);
  const [timer, settimer] = React.useState(0)


  const fetchData = async () => {

    await axios({
      method: "GET",
      url: `${url}/verifiedevents`,

    }).then(res => {
      dispatch(setGenEvents(JSON.parse(cryptojs.AES.decrypt(res.data.docs, 'kowshik123').toString(cryptojs.enc.Utf8))))
    }).catch(err => {
      // // console.log(err)
    })

    await axios.get(`${url}/getverifiedLEEDevents`)
      .then((res) => {
        dispatch(setLEEDEvents(JSON.parse(cryptojs.AES.decrypt(res.data.docs, 'kowshik123').toString(cryptojs.enc.Utf8))))

      }).catch((error) => {
        console.error('Error fetching data:', error);
      });
  }
  React.useEffect(() => {
    fetchData()
  }, [])
  React.useEffect(() => {
    axios({
      method: "GET",
      url: `${url}/pitchesST`,
    }).then((res) => {

      dispatch(setPitches(JSON.parse(cryptojs.AES.decrypt(res.data.docs, 'kowshik123').toString(cryptojs.enc.Utf8))))
    }).catch(err => {
      // // console.log(err)
    })
  }, [])


  React.useEffect(() => {
    // Simulate loading time
    if (sessionStorage.getItem('isloading') == undefined || sessionStorage.getItem('isloading') == null) {
      let x = setTimeout(() => {
        setIsLoading(false);
        sessionStorage.setItem('isloading', false)
      }, 3000);
      let y = setInterval(() => {
        settimer((prev) => prev + 30)

      }, 900)
      setTimeout(() => {
        clearTimeout(x);
        clearInterval(y);
      }, 3500);
    }


  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  React.useEffect(() => {
    if (location.pathname.startsWith("/flagship") || location.pathname.startsWith("/flagshipexpert")) {
      if (sessionStorage.getItem('userid') == null || sessionStorage.getItem('userid') == undefined) {
        navigate('/login')
      }
    }


  })

  return (

    <div className="w-screen overflow-x-hidden flex flex-col ">
      {
        <div className='w-full  min-h-screen max-h-fit '>
          <LoadingScreen
            loading={creatingpitch}
            bgColor='#f1f1f1'
            spinnerColor='#9ee5f8'
            textColor='#676767'
            text='Hang On Creating Your Pitch'
          ></LoadingScreen>


          <LoadingScreen
            loading={loggingstatus}
            bgColor='#f1f1f1'
            spinnerColor='#9ee5f8'
            textColor='#676767'
            text='Hang On logging In'
          >
          </LoadingScreen>

          <LoadingScreen
            loading={logoutstatus}
            bgColor='#f1f1f1'
            spinnerColor='#9ee5f8'
            textColor='#676767'
            text='Hang On logging Out'
          >
          </LoadingScreen>
          <NavInApp />
          {!creatingpitch && <div >
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="*" element={<Home />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/team" element={<Member />} />

              <Route exact path="/flagshipexpert" element={<STexpert />} >
                <Route exact path="/flagshipexpert/" element={<Pitches expert={true} />} />
                <Route exact path="reports" element={<Reports />}></Route>
                <Route path="comments" element={<Comments />}></Route>
              </Route>
              <Route exact path="/flagship" element={<ST />}>
                {/* <Route exact path="/flagship/" element={<HomeST />} /> */}
                <Route path="createpitchST" element={<UserPitch />} />
                <Route path="yourpitchST" element={<YourPitch />} />
              </Route>
              <Route exact path="/kct/Leed/Admin" element={<Requests />} />
              <Route exact path="/events" element={<Events />}></Route>
              <Route path="/allevents" element={<LEEDevents />} />
              <Route path="/eventregister" element={<Eventregister />} />
              <Route path="/aboutus" element={<AboutUs />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </div>}
        </div>}
    </div>
  );
}

export default App;
