import React from 'react'
import { Calendar, dayjsLocalizer, globalizeLocalizer, momentLocalizer, dateFnsLocalizer } from 'react-big-calendar'
import "react-big-calendar/lib/css/react-big-calendar.css"
import infopic from "../Assets/info.png"
import "react-datepicker/dist/react-datepicker.css"
import Switch from "react-switch";
import { Link, Outlet, useNavigate } from 'react-router-dom';
import GeneralEvents from './generalevents';
import LEEDevents from './LEEDevents';
import { toast, ToastContainer } from "react-toastify";
import calendarimg from '../Assets/calendar.png'
import KCTImage from '../Assets/KCTLOGO.png'
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios'
import { useSelector } from 'react-redux'
function Events() {
  let location = useLocation()
  const navigate = useNavigate()
  const url = useSelector((state) => state.url.value)
  const [checked, setChecked] = React.useState(true);
  const handleChange = nextChecked => {
    setChecked(nextChecked);
  };
  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    if (id)
      handleChange(true)
    axios.get(`${url}/eventspagevisit`)
      .then((res) => console.log(res))
      .catch((err) => console.log(err))

    if (location.state && location.state.data == "success") {
      toast.success('Success! Application sent', {
        autoClose: 3000,
        position: toast.POSITION.BOTTOM_CENTER, className: 'toast-message'
      })
    }
  }, [])
  return (
    <div className='' >
      <Helmet>
        <title>COLLEGE EVENTS | LEED KCT</title>
        <meta name="description" content="Discover upcoming college events. Find details about various events, dates, venues, and more. Stay updated with the latest happenings in the city." />
      </Helmet>
      {/* <div className="d-flex justify-content-center">
          {!checked && <p className='mt-1' style={{ fontSize: 15, color: 'grey', width: 150 }}>GENERAL EVENTS</p>}
          <Switch
            onChange={handleChange}
            checkedIcon={false}
            checked={checked}
            uncheckedIcon={false}

          />
          {checked && <p className='mt-1' style={{ fontSize: 15, color: 'grey', width: 150 }}>LEED EVENTS</p>}
        </div> */}
      {/* <div className="d-flex w-100 justify-content-center align-items-center"><img src={calendarimg} style={{ height: 35, width: 35 }} alt="" /> &nbsp;&nbsp;<div style={{ fontFamily: 'Bebas Neue', fontSize: 35, fontWeight: 500 }} className='text-success ' >{!checked ? 'GENERAL EVENTS' : 'LEED EVENTS'}</div></div> */}
      {!checked ?
        <div className=' d-flex flex-column align-items-center'>
          <div className=''>
            <GeneralEvents />
            <br />
          </div>
          <div className={window.innerWidth > 500 && 'w-75 d-flex flex-column align-items-center'} style={{ background: 'white', boxShadow: '0 0 5px grey', fontFamily: 'Inter', width: window.innerWidth <= 500 && '90%' }}>
            <br />
            <p className='text-success '>Register Your Event Too ?</p>
            <h3 style={{ fontFamily: 'Inter', fontWeight: 700 }}>Apply To LEED Events</h3>
            <p className={window.innerWidth > 500 ? 'text-primary  w-50 d-flex align-items-center mt-2' : 'text-primary mt-2 text-start p-1'}><img src={infopic} style={{ height: 30, width: 30 }} alt="" />Adhere to the instructions .</p>
            <p className={window.innerWidth > 500 ? 'text-primary  w-50 d-flex align-items-center mt-2' : 'text-primary mt-2 text-start p-1'}><img src={infopic} style={{ height: 30, width: 30 }} alt="" />Kindly follow terms and conditions .</p>
            <p className={window.innerWidth > 500 ? 'text-primary  w-50 d-flex align-items-center mt-2' : 'text-primary mt-2 text-start p-1'}><img src={infopic} style={{ height: 30, width: 30 }} alt="" />Events from organizations are accepted .</p>
            <p className={window.innerWidth > 500 ? 'text-primary text-light w-50 d-flex align-items-center mt-2' : 'text-primary mt-2 text-start text-light p-1'} style={{ background: 'rgba(0,0,0,0.5)' }}><img src={infopic} style={{ height: 30, width: 30 }} alt="" />Events from individuals will not be entertained !</p>
            <img src={KCTImage} style={{ height: '20%', width: '40%' }} alt="" />
            <Link to={sessionStorage.getItem('email') && "/eventregister"} onClick={() => {
              if (!sessionStorage.getItem('email')) {
                toast.warning(<div className='d-flex align-items-center'>Kindly <div className='text-primary mx-1' onClick={() => navigate("/login")}> Login </div> before registration</div>, {
                  autoClose: 5000,
                  position: toast.POSITION.BOTTOM_CENTER
                })
              }
            }} className={window.innerWidth > 500 ? 'btn btn-primary my-3 w-25' : 'btn btn-primary my-3 w-75'}>Register Your Event </Link>
            <ToastContainer />
          </div>
          <br /><br />
        </div>
        :
        <LEEDevents />}
    </div>
  )
}

export default Events
