import ReactFlow, { MarkerType, Position } from "reactflow";

import "reactflow/dist/style.css";

const initialNodes = [
  {
    id: "1",
    position: { x: -150, y: 0 },
    type: "custom",
    style: {
      background: "linear-gradient(to top right, #2563eb, #2dd4bf)",
      border: "none",
      color: "white",
      borderRadius: "15px",
      padding: 10,
      width: 250,
      height: 100,
      boxShadow: " 0 15px 50px -12px rgba(0, 0, 0, 0.5)",
    },
    data: {
      label: (
        <div className="text-lg flex w-full h-full  items-center justify-center">
          Pitch Your Idea
        </div>
      ),
    },
    sourcePosition: Position.Right,
  },
  {
    id: "2",
    position: { x: 400, y: 0 },
    type: "custom",
    style: {
      background: "linear-gradient(to top right, #2563eb, #2dd4bf)",
      border: "none",
      color: "white",
      borderRadius: "15px",
      padding: 10,
      width: 250,
      height: 100,
      boxShadow: " 0 15px 50px -12px rgba(0, 0, 0, 0.5)",
    },
    data: {
      label: (
        <div className="text-lg  flex w-full h-full  items-center justify-center">
          Get Reviewed by the panel.
        </div>
      ),
    },
    targetPosition: Position.Left,
  },
  {
    id: "3",
    position: { x: 400, y: 150 },
    type: "custom",
    style: {
      background: "linear-gradient(to top right, #2563eb, #2dd4bf)",
      border: "none",
      color: "white",
      borderRadius: "15px",
      padding: 10,
      width: 250,
      height: 100,
      boxShadow: " 0 15px 50px -12px rgba(0, 0, 0, 0.5)",
    },
    data: {
      label: (
        <div className="text-lg flex w-full h-full  items-center justify-center">
          Present you idea
        </div>
      ),
    },
    sourcePosition: Position.Left,
  },
  {
    id: "4",
    position: { x: -150, y: 150 },
    type: "custom",
    style: {
      background: "linear-gradient(to top right, #2563eb, #2dd4bf)",
      border: "none",
      color: "white",
      borderRadius: "15px",
      padding: 10,
      width: 250,
      height: 100,
      boxShadow: " 0 15px 50px -12px rgba(0, 0, 0, 0.5)",
    },
    data: {
      label: (
        <div className="text-lg flex w-full h-full  items-center justify-center">
          Get Approval and Fund
        </div>
      ),
    },
    targetPosition: Position.Right,
  },
  {
    id: "5",
    type: "default",
    className: "annotation",
    position: { x: -150, y: 300 },
    style: {
      background: "linear-gradient(to top right, #2563eb, #2dd4bf)",
      border: "none",
      color: "white",
      borderRadius: "15px",
      padding: 10,
      width: 250,
      height: 100,
      boxShadow: " 0 15px 50px -12px rgba(0, 0, 0, 0.5)",
    },
    data: {
      label: (
        <div className="text-lg flex w-full h-full  items-center justify-center">
          Start to your entrepreneurial journey
        </div>
      ),
    },
  },
];
const initialEdges = [
  {
    id: "e1-2",
    source: "1",
    target: "2",
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
    animated: true,
  },
  {
    id: "e2-3",
    source: "2",
    target: "3",
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
    animated: true,
  },
  {
    id: "e3-4",
    source: "3",
    target: "4",
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
    animated: true,
  },
  {
    id: "e4-5",
    source: "4",
    target: "5",
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
    animated: true,
  },
];

const mobileInitialNodes = [
  {
    id: "1",
    position: { x: 0, y: 0 },
    type: "custom",
    style: {
      background: "linear-gradient(to top right, #2563eb, #2dd4bf)",
      border: "none",
      color: "white",
      borderRadius: "15px",
      padding: 10,
      width: 250,
      height: 100,
      boxShadow: " 0 15px 50px -12px rgba(0, 0, 0, 0.5)",
    },
    data: {
      label: (
        <div className="text-lg flex w-full h-full  items-center justify-center">
          Pitch Your Idea
        </div>
      ),
    },
    sourcePosition: Position.Right,
  },
  {
    id: "2",
    position: { x: 0, y: 120 },
    type: "custom",
    style: {
      background: "linear-gradient(to top right, #2563eb, #2dd4bf)",
      border: "none",
      color: "white",
      borderRadius: "15px",
      padding: 10,
      width: 250,
      height: 100,
      boxShadow: " 0 15px 50px -12px rgba(0, 0, 0, 0.5)",
    },
    data: {
      label: (
        <div className="text-lg  flex w-full h-full  items-center justify-center">
          Get Reviewed by the panel.
        </div>
      ),
    },
    targetPosition: Position.Left,
  },
  {
    id: "3",
    position: { x: 0, y: 240 },
    type: "custom",
    style: {
      background: "linear-gradient(to top right, #2563eb, #2dd4bf)",
      border: "none",
      color: "white",
      borderRadius: "15px",
      padding: 10,
      width: 250,
      height: 100,
      boxShadow: " 0 15px 50px -12px rgba(0, 0, 0, 0.5)",
    },
    data: {
      label: (
        <div className="text-lg flex w-full h-full  items-center justify-center">
          Present you idea
        </div>
      ),
    },
    sourcePosition: Position.Left,
  },
  {
    id: "4",
    position: { x: 0, y: 360 },
    type: "custom",
    style: {
      background: "linear-gradient(to top right, #2563eb, #2dd4bf)",
      border: "none",
      color: "white",
      borderRadius: "15px",
      padding: 10,
      width: 250,
      height: 100,
      boxShadow: " 0 15px 50px -12px rgba(0, 0, 0, 0.5)",
    },
    data: {
      label: (
        <div className="text-lg flex w-full h-full  items-center justify-center">
          Get Approval and Fund
        </div>
      ),
    },
    targetPosition: Position.Right,
  },
  {
    id: "5",
    position: { x: 0, y: 480 },
    type: "default",
    className: "annotation",
    style: {
      background: "linear-gradient(to top right, #2563eb, #2dd4bf)",
      border: "none",
      color: "white",
      borderRadius: "15px",
      padding: 10,
      width: 250,
      height: 100,
      boxShadow: " 0 15px 50px -12px rgba(0, 0, 0, 0.5)",
    },
    data: {
      label: (
        <div className="text-lg flex w-full h-full  items-center justify-center">
          Start to your entrepreneurial journey
        </div>
      ),
    },
  },
];

export default function Processflow() {
  return (
    <div className="w-screen h-[90vh]  md:px-0  flex flex-col md:flex-row items-start md:items-center justify-start">
      <div className="w-full md:w-2/5 h-fit mb-20 flex flex-col items-center justify-center">
        <h1 className="text-slate-600 px-5 text-4xl">
          Path To Your{" "}
          <span className="block font-extrabold text-5xl text-transparent my-2 bg-gradient-to-r from-blue-600 to-teal-400 bg-clip-text ">
            Entrepreneurial
          </span>{" "}
          Journey starts here.
        </h1>
      </div>
      <div className="h-full hidden md:block lg:block xl:block w-full md:w-3/5 pointer-events-none">
        <ReactFlow
          nodes={initialNodes}
          zoomOnPinch={false}
          zoomOnScroll={false}
          zoomOnDoubleClick={false}
          edges={initialEdges}
          draggable={false}
          padd
          fitView
        />
      </div>
      <div className="h-full w-full block md:hidden md:w-3/5 pointer-events-none">
        <ReactFlow
          nodes={mobileInitialNodes}
          zoomOnPinch={false}
          zoomOnScroll={false}
          zoomOnDoubleClick={false}
          edges={initialEdges}
          draggable={false}
          padd
          fitView
        />
      </div>
    </div>
  );
}
