import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import cardimage1 from "../Assets/5363874.jpg";
import {
  TbSquareRoundedArrowLeftFilled,
  TbSquareRoundedArrowRight,
} from "react-icons/tb";

const EventCarousel = (props) => {
  const [Email, setEmail] = React.useState("");
  const navigate = useNavigate();
  const experts = useSelector((state) => state.experts.value);
  function scrollCarousel(x){
    document
      .getElementById("scrollingEvent")
      .scrollBy({ left: 300 * x, behavior: "smooth" });
  }

  const events = [
    {
      title: "Idea Factory",
      desc: "Talk Session on  Design Thinking",
      img: "/idea-factory.jpeg",
    },
    {
      title: "Business Model Canvas Insights ",
      desc: "Gain valuable insights into business strategy at the Business Canvas Insights Session. An illuminating talk which will delve into the nuances of the Business Model Canvas, providing practical insights and real-world applications.",
      img: "/business-model.jpeg",
    },
    {
      title: "Entrepreneural Challenge",
      desc: "Engage in the Entrepreneural Challenge, featuring diverse rounds such as a business trivia quiz, logo identification, an exploration of entrepreneurial legacies, and a creative Business Pictionary, testing your knowledge and innovation in the dynamic world of entrepreneurship.",
      img: "/engage.jpeg",
    },
  ];
  return (
    <div className="w-screen pt-20 py-40  overflow-x-hidden no-scrollbar">
      <h2 className="text-5xl w-full text-center text-blue-500">Events</h2>
      <p className="text-center">Find the list events in our oragnisation.</p>

      <div className="w-screen   flex flex-col md:flex-row items-center justify-center">
        <div
          id="scrollingEvent"
          className="w-[90%]  no-scrollbar overflow-x-scroll snap-x snap-mandatory h-fit pt-10 py-20"
        >
          <div className="md:px-10 lg:px-20 snap-end min-w-min flex flex-row items-stretch justify-stretch gap-24">
            <div
              className="w-[90vw]  lg:w-[23vw]  shadow-2xl mx-auto snap-center rounded-3xl relative overflow-hidden h-[60vh] flex flex-col bg-blue-500"
              onClick={() => navigate("/events")}
            >
              <div className="w-full bg-slate-400  h-1/2">
                <img
                  className="h-full w-full object-cover"
                  src={cardimage1}
                  alt=""
                />
              </div>
              <div className="h-1/2 flex flex-col items-start  justify-between py-3  bg-gradient-to-tr from-blue-600 to-teal-400 w-full text-white px-10">
                <p className="text-2xl md:text-4xl lg:text-xl">
                  Register your events to LEED
                </p>
                <p className="text-xs md:text-xl lg:text-xs font-light">
                  Enroll to promote events for free | Get a chance to publish
                  your events
                </p>
                <div className="w-full flex gap-6 lg:gap-1 flex-row items-stretch justify-start">
                  <button className=" bg-white  text-blue-600 lg:px-2 lg:py-2 px-2 py-2 md:px-4 md:py-3 rounded-xl ">
                    Register
                  </button>
                </div>
              </div>
            </div>
            {props.leedEvents.map((val, ind) => (
              <div
                key={val.title}
                className="w-[90vw]  lg:w-[23vw]  shadow-2xl mx-auto snap-start rounded-3xl relative overflow-hidden h-[60vh] flex flex-col bg-blue-500"
                onClick={() =>
                  navigate(
                    Email === experts.expert1 ||
                      Email === experts.expert2 ||
                      Email === experts.expert3
                      ? "flagshipexpert"
                      : Email.length > 0
                      ? "flagship"
                      : "/login"
                  )
                }
              >
                <div className="w-full bg-slate-400 h-1/2">
                  {val.pic && val.pic.length > 0 ? (
                    <img
                      className="h-full w-full object-cover"
                      src={val.pic[0]["secure_url"]}
                      alt=""
                    />
                  ) : (
                    <div className="h-full w-full bg-slate-300"></div>
                  )}
                </div>
                <div className="h-1/2 flex flex-col items-start  justify-center    bg-gradient-to-tr from-blue-600 to-teal-400 w-full text-white px-10">
                  <p className="text-2xl md:text-4xl lg:text-xl">{val.title}</p>
                  <p className="text-xs md:text-xl lg:text-xs font-light">
                    {val.desc.slice(0, 100)}...
                  </p>

                  <div className="w-full flex gap-6 lg:gap-1 flex-row items-stretch justify-start">
                    <button className=" border-white border-[1px]  lg:px-2 lg:py-2 px-2 py-2 md:px-4 md:py-3 rounded-xl ">
                      more details
                    </button>
                    {/* <button className=" bg-white  text-blue-600 lg:px-2 lg:py-2 px-2 py-2 md:px-4 md:py-3 rounded-xl ">
                      Collaborate
                    </button> */}
                  </div>
                </div>
              </div>
            ))}
            {events.map((val, ind) => (
              <div
                key={val.title}
                className="w-[90vw]  lg:w-[23vw]  shadow-2xl mx-auto snap-start rounded-3xl relative overflow-hidden h-[60vh] flex flex-col bg-blue-500"
                onClick={() =>
                  navigate(
                    Email === experts.expert1 ||
                      Email === experts.expert2 ||
                      Email === experts.expert3
                      ? "flagshipexpert"
                      : Email.length > 0
                      ? "flagship"
                      : "/login"
                  )
                }
              >
                <div className="w-full bg-slate-400 h-1/2">
                  <img
                    className="h-full w-full object-cover"
                    src={val.img}
                    alt="image"
                  />
                </div>
                <div className="h-1/2 flex flex-col items-start  justify-center    bg-gradient-to-tr from-blue-600 to-teal-400 w-full text-white px-10">
                  <p className="text-2xl md:text-4xl lg:text-xl">{val.title}</p>
                  <p className="text-xs md:text-xl lg:text-xs font-light">
                    {val.desc.slice(0, 100)}...
                  </p>

                  <div className="w-full flex gap-6 lg:gap-1 flex-row items-stretch justify-start">
                    <button className=" border-white border-[1px]  lg:px-2 lg:py-2 px-2 py-2 md:px-4 md:py-3 rounded-xl ">
                      more details
                    </button>
                    {/* <button className=" bg-white  text-blue-600 lg:px-2 lg:py-2 px-2 py-2 md:px-4 md:py-3 rounded-xl ">
                      Collaborate
                    </button> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-1/2 md:w-[10%] flex flex-row md:flex-col items-center justify-center h-full  ">
          <TbSquareRoundedArrowLeftFilled
            onClick={() => scrollCarousel(-1)}
            className="cursor-pointer text-blue-500"
            size={60}
          />
          <TbSquareRoundedArrowRight
            onClick={() => scrollCarousel(1)}
            className="cursor-pointer text-blue-500"
            size={60}
          />
        </div>
      </div>
    </div>
  );
};

export default EventCarousel;
