import React from "react";
import { BsEnvelopeFill, BsLinkedin } from "react-icons/bs";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

const Member = () => {
  const members = [
    {
      name: "Kiran Srinivas S",
      position: "President",
      imagePathName: "kiran.jpeg",
      mail: "kiransrinivas.20ee@kct.ac.in",
      linkedin: "https://www.linkedin.com/in/kiran-srinivas-s-99a5b3184",
    },
    {
      name: "Singaram",
      position: "Vice President",
      imagePathName: "Singaram 21BEC143.jpg",
      mail: "singaram.21ec@kct.ac.in",
      linkedin: "https://www.linkedin.com/in/singaram-s-aa593316b",
    },
    {
      name: "Sanju Vikasini",
      position: "KCLAS Vice President",
      imagePathName: "Sanju vikasini 22BIB043.jpg",
      mail: "sanjuvikasini.22bib@kclas.ac.in",
      linkedin: "https://www.linkedin.com/in/sanju-vikasini-v-4ab383258?",
    },

    {
      name: "Kavin Kumar",
      position: "Treasurer",
      imagePathName: "kavinkumar.jpg",
      mail: "kavinkumar.21ee@kct.ac.in",
      linkedin: "https://www.linkedin.com/in/kavinkumar-s-p",
    },
    {
      name: "Dakshindaya V S",
      position: "Joint Secretary",
      imagePathName: "Dakshindaya 21BIT010.jpg",
      mail: "Dakshindaya.21it@kct.ac.in ",
      linkedin: "https://www.linkedin.com/in/dakshindaya-v-s-813ab8253",
    },
    {
      name: "Vinu Madavan",
      position: "Joint Treasurer",
      imagePathName: "Vinu Madavan 22BBT066.jpg",
      mail: "vinumadavan.22bt@kct.ac.in",
      linkedin: "https://www.linkedin.com/in/vinu-madavan-711387188",
    },
    {
      name: "Peragathis",
      position: "Club Manager",
      imagePathName: "Peragathis 22BBT036.jpg",
      mail: "peragathis.22bt@kct.ac.in",
      linkedin: "https://www.linkedin.com/in/peragathis",
    },
    {
      name: "Hariharan",
      position: "Coorporate Head",
      imagePathName: "Harihaaran.D.K 20BEI017.jpg",
      mail: "",
      linkedin: "",
    },

    {
      name: "Obuli Manikandan",
      position: "Operations Head",
      imagePathName: "Obuli Manikandan.jpg",
      mail: "obulimanikandan.22tt@kct.ac.in",
      linkedin: "https://www.linkedin.com/in/obuli-manikandan-ab376825a",
    },

    {
      name: "Muthu Prasanna",
      position: "Financial planning coordinator",
      imagePathName: "Muthu Prasanna 22BTT017.jpg",
      mail: "muthuprasanna.22tt@kct.ac.in",
      linkedin: "",
    },
    {
      name: "Pragathi Venkatesan",
      position: "Documentation Head",
      imagePathName: "pragathi venkatesan.jpg",
      mail: "pragathi.23bds@kclas.ac.in",
      linkedin: "https://www.linkedin.com/in/pragathi-venkatesan-a99a76281",
    },

    {
      name: "Varnika",
      position: "Program Chair",
      imagePathName: "Varnika 23BIB057.jpg",
      mail: "varnika.23bib@kclas.ac.in",
      linkedin: "",
    },
    {
      name: "Uditha S",
      position: "Marketing Executive",
      imagePathName: "Uditha S -23BPY041.jpg",
      mail: "uditha.23bpy@kclas.ac.in",
      linkedin: "",
    },
    {
      name: "Mahipoojaa",
      position: "Brand Advocate",
      imagePathName: "Mahipoojaa - 22BPY031.jpg",
      mail: "mahipoojaa.22bpy@kclas.ac.in",
      linkedin: "https://www.linkedin.com/in/mahipoojaa-m-52b431254",
    },
    {
      name: "Mahalakshmi",
      position: "Alumni Outreach Coordinator",
      imagePathName: "Mahalakshmi 22bpy030.jpg",
      mail: "mahalakshmi.22bpy@kclas.ac.in",
      linkedin: "https://www.linkedin.com/in/mahalakshmi-a-86506326b",
    },
    {
      name: "Suyash",
      position: "Membership Coordinator",
      imagePathName: "Suyash 22BEC179.jpg",
      mail: "",
      linkedin: "",
    },
    {
      name: "Sri Yogesh",
      position: "Technical Head",
      imagePathName: "Sri Yogesh 22BAD098.jpeg",
      mail: "",
      linkedin: "",
    },
    {
      name: "Nisaanth",
      position: "Corporate Co-Head",
      imagePathName: "Nishaanth 22BEC090.jpg",
      mail: "",
      linkedin: "",
    },
    {
      name: "Akila",
      position: "External Events Head",
      imagePathName: "Akila 22BFT003.jpg",
      mail: "",
      linkedin: "",
    },
    {
      name: "Riya",
      position: "Internal Events Head",
      imagePathName: "riya.png",
      mail: "",
      linkedin: "",
    },
    {
      name: "Karpagha Sreedharan",
      position: "Social Media Lead",
      imagePathName: "21BEC143.jpg",
      mail: "karpaghasreedharan.21bpa@kclas.ac.in",
      linkedin: "https://www.linkedin.com/in/karpagha-sreedharan-9b3a85134",
    },
  ];
  return (
    <div className="pb-20">
      <div className="h-[30vh] relative lg:h-screen flex items-center justify-center w-screen ">
        <div className="z-10 w-1/4 absolute top-1/4 left-1/2  -translate-x-1/2 hidden lg:block mb-40 h-fit shadow-2xl shadow-black bg-[#fdfefa] rounded-xl py-2 px-4">
          <img
            src="/Assets/leedBadges.jpg"
            className="h-1/4  -rotate-1 w-full  "
            alt="leedbadges"
          />
        </div>
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          totalSlides={4}
          infinite={true}
          interval={1500}
          isPlaying={true}
          className="w-full h-full bg-blue-100"
        >
          <Slider className="w-full h-full  bg-blue-100 ">
            <Slide
              className="w-screen h-screen flex items-center justify-center"
              index={0}
            >
              <img
                src="/Assets/members-sm.jpg"
                className="w-full object-cover"
                alt="members image"
              />
            </Slide>
            <Slide
              className="w-screen h-screen flex items-center justify-center"
              index={1}
            >
              <img
                src="/Assets/IMG_1297.jpg"
                className="w-full object-cover"
                alt="members image"
              />
            </Slide>
            <Slide
              className="w-screen h-screen flex items-center justify-center"
              index={2}
            >
              <img
                src="/Assets/IMG_1295.jpg"
                className="w-full object-cover"
                alt="members image"
              />
            </Slide>
            <Slide
              className="w-screen h-screen flex items-center justify-center"
              index={3}
            >
              <img
                src="/Assets/IMG_1278.jpg"
                className="w-full object-cover"
                alt="members image"
              />
            </Slide>
          </Slider>
          {/* <ButtonBack>Back</ButtonBack>
          <ButtonNext>Next</ButtonNext> */}
        </CarouselProvider>
        {/* <img
          src="/Assets/IMG_1297.jpg"
          className="absolute left-0 top-0 h-full w-full object-cover"
          alt="members image"
        /> */}
      </div>
      <h1 className="text-5xl lg:text-7xl w-fit text-center  text-[#b2d235] bg-clip-text mx-auto font-bold italic mt-10 ">
        Our Team
      </h1>
      <div className="w-full mt-14 grid gap-7 lg:gap-10 grid-cols-1 lg:grid-cols-3 px-2 lg:px-20">
        {members.map((member) => (
          <div className=" flex flex-row gap-5  border-[0.5px] text-center items-center justify-between shadow-lg bg-slate-50 lg:shadow-2xl  rounded-2xl px-3 py-2">
            <div className=" h-5/6 rounded-3xl w-2/5 bg-transparent overflow-hidden object-contain">
              <img
                loading="lazy"
                src={`/members/${member.imagePathName}`}
                className="h-full w-full bg-slate-100  rounded-3xl object-cover"
                alt={`${member.name}'s profiles`}
              />
            </div>
            <div className="w-3/5 ">
              <h1 className="text-2xl text-left font-bold italic text-[#b2d235]">
                {member.name}
              </h1>
              <p className="bg-[#b2d235] relative right-2 mt-3 text-white px-4 py-2 rounded-3xl lg:w-3/4 text-center font-semibold text-xs">
                {member.position}
              </p>
              <div className="flex flex-row items-center gap-3">
                <a target="_blank" href={member?.linkedin}>
                  <BsLinkedin className="text-[#b2d235]" size={25} />
                </a>
                <a href={`mailto:${member?.mail}`}>
                  <BsEnvelopeFill className="text-[#b2d235]" size={30} />
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Member;
