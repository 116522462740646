import React from 'react'
import DateToDay from '../DateToDay';
import ExpertReportST from './expert'
import uparrow from '../../Assets/up-arrow.png'
import uparrow1 from '../../Assets/up-arrow (1).png'
import linkedinimg from '../../Assets/linkedin.png'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import commentpic from '../../Assets/comment.png'
function PitchTemplate({ val, userid, expert, email }) {
  const dispatch = useDispatch()
  const url = useSelector((state) => state.url.value)
  const experts = useSelector((state) => state.experts.value)
  const [upvotes, setupvotes] = React.useState(val['upvotes'])
  const [opencomment, setopencomment] = React.useState("")
  const [disablelike, setdisablelike] = React.useState(false)
  const [expertno, setexpertno] = React.useState('expert3')


  React.useEffect(() => {
    if (email) {
      setexpertno(email === experts.expert1
        ? 'expert1'
        : email === experts.expert2
          ? 'expert2'
          : 'expert3')
    }
  }, [email])
  function Likedfunction(id, pitchid) {
    setdisablelike(true)
    if (!upvotes.includes(userid))
      setupvotes((prev) => prev.concat(userid));
    else
      setupvotes((prev) => prev.filter(item => item != userid))
    axios.post(`${url}/likepitchST`, {
      id: userid,
      pitchid: pitchid
    })
    setdisablelike(false)
  }
  return (
    <>
      <div className={window.innerWidth > 500 ? 'pitch bg-light my-3 p-1' : 'pitch bg-light my-3 '} style={{ boxShadow: '0 0 10px grey', borderRadius: 20, width: '95%' }}>
        <div style={{ background: 'rgba(200,200,200,0.5)', fontWeight: 500, borderTopRightRadius: 20, borderTopLeftRadius: 20, letterSpacing: '2px' }} className={window.innerWidth > 500 ? "d-flex justify-content-between p-4 align-items-center" : "d-flex p-3 flex-column align-items-center"}>
          <div className={window.innerWidth > 500 ? "d-flex w-25 justify-content-between align-items-center" : "d-flex justify-content-between "}>  <div>{val['name']}</div>     {(val['linkedin'] && val['linkedin'].length > 0) && <a target='_blank' href={val['linkedin']}> <img src={linkedinimg} style={{ width: 20, height: 20 }} alt="" /> </a>}</div>
          <div className={window.innerWidth > 500 ? "d-flex w-50 justify-content-between align-items-center" : "d-flex w-100 my-1 justify-content-evenly"}>
            <div>{val['category']}</div>
            <div>{DateToDay(val['createdAt'].slice(0, 10))}</div>
          </div>
        </div> <br />
        <h5><b>{val['title']}</b></h5>
        <h6 className='p-2'>{val['content']}</h6>
        {val["users"].length > 0 && <div className={window.innerWidth > 500 ? 'w-100 p-5' : 'w-100 text-center'} style={{ textAlign: 'start' }}>
          <h5 className='text-secondary'>Target Users</h5>
          <p>{val['users']}</p>
        </div>}
        {val["impact"].length > 0 && <div className={window.innerWidth > 500 ? 'w-100 p-5' : 'w-100 text-center'} style={{ textAlign: 'start' }}>
          <h5 className='text-secondary'>Solution Impact</h5>
          <p>{val['impact']}</p>
        </div>}
        {val["barriers"].length > 0 && <div className={window.innerWidth > 500 ? 'w-100 p-5' : 'w-100 text-center'} style={{ textAlign: 'start' }}>
          <h5 className='text-secondary'>Adoption Barriers</h5>
          <p>{val['barriers']}</p>
        </div>}
        <div className="w-100 d-flex justify-content-around" style={{ flexWrap: 'wrap' }}>
          {val['image'].length > 0 && val['image'].map((value, index) =>
            <div className='my-5' style={{ width: '40%' }}>
              <img src={value['secure_url']} style={{ maxWidth: '100%', maxHeight: 300, objectFit: 'contain' }} alt="" />
            </div>
          )}
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'center' }}>
          {val['video'].length > 0 && val['video'].map((value, index) =>
            <div className='my-3 w-100'>
              <video src={value['secure_url']} style={{ maxWidth: '90%', minWidth: '55%', border: '1px solid black', maxHeight: '50vh' }} controls /> <br />
            </div>
          )}
        </div>  <br />
        {val['gdrive'].length > 0 && val['gdrive'].map((value, ind) => {

          return <div className='w-100 d-flex flex-column align-items-center border border-dark'>
            <embed src={value.replace("/view?usp=sharing", "/preview")} style={{ width: '75%' }} height="400">
            </embed> <br /> <a style={{ fontWeight: '600' }} href={val} target="_blank">DOWNLOAD DOCUMENT</a> <br />
          </div>
        })}
        <br />

        <hr />
        <br />

        <div className="d-flex w-100 justify-content-evenly align-items-center  " style={{ flexWrap: 'wrap' }}>
          <div className="d-flex align-items-center m-1">
            <button className='btn btn-light shadow-sm' onClick={() => Likedfunction(userid, val["_id"])} disabled={disablelike}>
              {upvotes.includes(userid) ? <> <div className="d-flex justify-content-center"><img src={uparrow1} style={{ width: 20, height: 20 }} alt="" />       <div style={{ fontWeight: 700 }}> {upvotes.length}</div> </div>  <div className='text-secondary'>Upvoted!</div> </> : <><div className="d-flex justify-content-center"><img src={uparrow} style={{ width: 20, height: 20 }} alt="" />       <div style={{ fontWeight: 700 }}> {upvotes.length}</div> </div>  <div className='text-secondary'>Upvote</div> </>}
            </button>

          </div>

          {(val['comment'][expertno] != "") ? <div className='d-flex flex-column m-1'><b className='text-success' style={{ cursor: 'pointer' }} onClick={() => {
            if (opencomment != val["_id"])
              setopencomment(val['_id']);
            else
              setopencomment("")
          }}>  Comments•</b> </div> : <><p><img src={commentpic} style={{ width: 20, height: 20 }} alt="" />No Comments</p></>
          }

        </div>
        <br />
        {opencomment == val['_id'] && <span>
          <hr />
          {val['comment']['expert1'] && <div >

            <p >{val['comment']['expert1']}</p>
          </div>}
          {val['comment']['expert2'] && <div >

            <p>{val['comment']['expert2']}</p>
          </div>} {val['comment']['expert3'] && <div >

            <p>{val['comment']['expert3']}</p>

          </div>}
          <hr />
        </span>}


        {expert && <ExpertReportST email={email} pitchid={val["_id"]} />}
        <br />
      </div></>
  )
}

export default PitchTemplate
