import React from "react";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import logo from "../Assets/logo.png";
import "./home.css";
import Footer from "../components/footer";
import { BsEnvelopeFill, BsLinkedin } from "react-icons/bs";
const cryptojs = require("crypto-js");
function Contact() {
  const navigate = useNavigate();
  const [loader, setloader] = React.useState(false);
  const [Email, setEmail] = React.useState("");
  React.useEffect(() => {
    if (
      sessionStorage.getItem("email") !== null &&
      sessionStorage.getItem("email") !== undefined
    ) {
      var bytes = cryptojs.AES.decrypt(
        sessionStorage.getItem("name"),
        "kowshik123"
      );
      setEmail(() => bytes.toString(cryptojs.enc.Utf8));
    }
  }, []);
  function sendemail(e) {
    e.preventDefault();
    if (!sessionStorage.getItem("email")) {
      toast.warning(
        <div className="d-flex align-items-center">
          Kindly{" "}
          <div className="text-primary mx-1" onClick={() => navigate("/login")}>
            {" "}
            Login{" "}
          </div>{" "}
          before posting
        </div>,
        {
          autoClose: 5000,
          position: toast.POSITION.BOTTOM_CENTER,
        }
      );
    } else {
      setloader(true);

      emailjs
        .sendForm(
          "service_wdt7318",
          "template_5ihujm8",
          e.target,
          "wkJzWA4km8ZmO0ljG"
        )
        .then(
          (result) => {
            setloader(false);
            toast.success("Success! Message sent", {
              autoClose: 3000,
              position: toast.POSITION.BOTTOM_CENTER,
              className: "toast-message",
            });
            e.target.reset();
          },
          (error) => {
            setloader(false);
            toast.warn(error.text, {
              autoClose: false,
              position: toast.POSITION.BOTTOM_CENTER,
              className: "toast-message",
            });
            e.target.reset();
          }
        );
    }
  }
  return (
    <div className="mt-24">
      <div className="w-screen min-h-screen max-h-fit flex flex-col items-center justify-center">
        <img
          src={logo}
          className="mx-auto"
          style={{
            width: 300,
            height: 100,
            maxWidth: "100%",
            objectFit: "contain",
          }}
          alt=""
        />
        <div className="py-10 px-3 flex lg:flex-row gap-10 items-stretch justify-center flex-col">
          <div className="border shadow-xl overflow-hidden  lg:w-3/5 w-full  flex flex-row rounded-3xl">
            <div className="flex w-fit flex-col justify-between  relative text-center  items-start  p-4">
              <p className="bg-blue-500  absolute top-5 left-5 px-4 text-lg py-1 rounded-3xl text-white">
                Contact Location
              </p>
              <div className="flex flex-col justify-start items-start">
                <p className="text-gray-600 text-lg mt-14">
                  KCT BUSINESS SCHOOL CABIN ,
                </p>
                <p className="text-gray-600 text-left text-lg">
                  KUMARAGURU COLLEGE OF TECHNOLOGY ,
                </p>
                <p className="text-gray-600 text-lg">COIMBATORE - 641049</p>
              </div>
              <div className="flex  flow-row gap-4 items-center justify-center">
                <BsLinkedin className="text-blue-500" size={50} />
                <BsEnvelopeFill className="text-blue-500" size={60} />
              </div>
              <h6 className="text-primary">leed@kct.ac.in</h6>
            </div>
          </div>

          <div className="border shadow-xl overflow-hidden  lg:w-3/5 w-full  flex flex-row rounded-3xl">
            <div className="flex w-fit flex-col justify-between  relative text-center  items-start  p-4">
              <p className="bg-blue-500  absolute top-5 left-5 px-4 text-lg py-1 rounded-3xl text-white">
                President
              </p>
              <div className="flex flex-col justify-center items-start">
                <h5 className="text-blue-600 text-4xl lg:text-5xl mt-14">
                  Kiran srinivas S
                </h5>
                <p className="text-gray-600 text-lg">
                  LEED
                </p>
                <p className="text-lg leading-3 text-blue-500">2023-2024</p>
              </div>
              <div className="flex  flow-row gap-4 items-center justify-center">
                <BsLinkedin className="text-blue-500" size={50} />

                <BsEnvelopeFill className="text-blue-500" size={60} />
              </div>
              <h6 className="text-primary">kiransrinivas.s@kct.ac.in</h6>
            </div>
          </div>
        </div>
        <div className="border shadow-xl overflow-hidden  lg:w-1/3 w-[94%]  flex flex-row rounded-3xl">
          <div className="flex w-fit flex-col justify-between  relative text-center  items-start  p-4">
            <p className="bg-blue-500  absolute top-5 left-5 px-4 text-lg py-1 rounded-3xl text-white">
              Staff-Coordinator
            </p>
            <div className="flex flex-col justify-center items-start">
              <h5 className="text-blue-600 text-4xl lg:text-5xl mt-14">
                Dr. Hari Baabu V
              </h5>
              <p className="text-gray-600 text-lg">
                Head of Center Of Entrepreneurship
              </p>
            </div>
            <div className="flex  flow-row gap-4 items-center justify-center">
              <BsLinkedin className="text-blue-500" size={50} />

              <BsEnvelopeFill className="text-blue-500" size={60} />
            </div>
            <h6 className="text-primary">haribaabu.v@kct.ac.in</h6>
          </div>
        </div>
        {/* <div className="border shadow-xl overflow-hidden  lg:w-1/2 w-fit flex flex-row rounded-3xl">
          <div className="flex w-fit flex-col justify-between  relative text-center  items-start  p-4">
            <p className="bg-blue-500  absolute top-5 left-5 px-4 text-lg py-1 rounded-3xl text-white">
              Work - Timings
            </p>
            <div className="flex flex-col justify-center items-start">
              <p className="text-gray-600 text-lg mt-14">
                Monday to saturday except on college holidays
              </p>
              <p className="text-lg leading-3 text-blue-500">10 AM - 7 PM</p>
            </div>
            <div className="flex  flow-row gap-4 items-center justify-center">
              <BsLinkedin className="text-blue-500" size={50} />
              <BsEnvelopeFill className="text-blue-500" size={60} />
            </div>
            <h6 className="text-primary">leed@kct.ac.in</h6>
          </div>
        </div> */}

        <div className="lg:px-0 lg:mt-0 mt-10 px-3 w-full lg:py-16">
          <Form
            onSubmit={sendemail}
            className="bg-gradient-to-tr  text-white from-blue-500 w-full lg:w-1/2 mx-auto to-blue-300 px-6  pt-5 shadow-2xl rounded-3xl flex flex-col items-center justify-evenly"
          >
            <h2 className="text-4xl text-white pb-3 w-full font-extrabold">Post your queries.</h2>

            <input type="text" value={Email} name="email" hidden />
            <Form.Group className="mt-2 mb-5  w-full" controlId="formBasicEmail">
              <Form.Label className="text-xl">Your profession</Form.Label>
              <Form.Control as="select" name="profile" required>
                <option value="Student">Student</option>
                <option value="Employee">Employee</option>
                <option value="Business Lead">Business Lead</option>
                <option value="Other professional">Prefer not to say</option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-5  w-full" controlId="formBasicEmail">
              <Form.Label className="text-xl">How helpful was our site?</Form.Label>
              <Form.Control as="select" name="remark" required>
                <option value="Good">Good</option>
                <option value="Average">Average</option>
                <option value="Bad">Bad</option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3  w-full" controlId="formBasicCheckbox">
              <Form.Label className="">Remarks</Form.Label>
              <Form.Control
                name="message"
                as="textarea"
                minLength={10}
                rows={5}
                label="Check me out"
              />
            </Form.Group>

            {loader ? (
              <Spinner
                style={{ alignSelf: "flex-end", marginTop: 10 }}
                animation="border"
                variant="danger"
              />
            ) : (
              <input
                className="bg-white text-2xl text-blue-500 px-3 py-3 rounded-xl w-full mb-10"
                style={{ alignSelf: "flex-end", marginTop: 10 }}
                type="submit"
                value="Submit"
              />
            )}
          </Form>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </div>
  );
}

export default Contact;
