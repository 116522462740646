import React from "react";
import "../Views/home.css";
import logo1 from "../Assets/Leed-Logo-01 (2).png";
import { useNavigate } from "react-router-dom";
function Footer() {
  const navigate = useNavigate();
  return (
    <footer className="w-screen h-fit overflow-y-hidden bg-white flex  text-white flex-col">
      <svg
        className="relative top-1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 300"
      >
        <path
          fill="#2563eb"
          fill-opacity="1"
          d="M0,96L60,112C120,128,240,160,360,165.3C480,171,600,149,720,128C840,107,960,85,1080,74.7C1200,64,1320,64,1380,64L1440,64L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
        ></path>
      </svg>
      <div className="flex flex-col overflow-y-hidden md:flex-row h-fit bg-blue-600  justify-between px-3 md:px-20 items-center">
        <div className="h-fit flex overflow-y-hidden flex-col items-center md:items-start justify-center gap-0  relative bottom-10 w-full md:w-1/2 ">
          <img src={logo1} alt="leed logo" className="w-1/2 object-contain mt-10" />
          <p className="text-5xl leading-3 lg:my-0 my-5 font-extrabold">LEED</p>
          <p className="text-2xl w-1/2 lg:block hidden text-left md:w-3/4">LEAGUE FOR ENTREPRENEURIAL <br />EMPOWERMENT & DEVELOPMENT</p>
          <p className="text-2xl w-1/2 lg:hidden text-center md:w-3/4">LEAGUE FOR ENTREPRENEURIAL <br />EMPOWERMENT & DEVELOPMENT</p>

          <p className="leading-3 ">Unlock your entrepreneurial journey with us.</p>
          <p className="text-yellow-400 font-extrabold leading-3">Kumaraguru Institutions.</p>
        </div>
        <div className="grid w-full  md:w-1/3  grid-cols-2 items-start justify-center ">
          <div className="mx-auto">
            <h5 style={{ fontWeight: "800" }}>Connect</h5>

            <p
              style={{ cursor: "pointer" }}
              className="text-start"
              onClick={() =>
                window.open(
                  `https://www.linkedin.com/company/leed-kumaraguru-college/`,
                  "_blank"
                )
              }
            >
              LINKEDIN
            </p>
            <p
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open(
                  `https://instagram.com/leed_kumaraguru?igshid=MzRlODBiNWFlZA==`,
                  "_blank"
                )
              }
            >
              INSTAGRAM
            </p>

          </div>
          <div className="mx-auto">
            <h5 style={{ fontWeight: "800" }}>Feedback</h5>

            <p style={{ cursor: "pointer" }}>MAIL</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
