import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { userReducer,userlogging,userlogout ,usercreatingpitch} from './Controllers/redux';
import { expertpitch }from './Controllers/redux';
import {yourpitches,expertcomment,expertreport,GenEvent,LEEDEvent,expertreducer,adminsreducer,urlreducer } from './Controllers/redux';
const root = ReactDOM.createRoot(document.getElementById('root'));
const store=configureStore({
reducer:{
  pitch:userReducer,
  expertpitch:expertpitch,
  yourpitch:yourpitches,
  expertcomment:expertcomment,
  expertreport:expertreport,
  generalevents:GenEvent,
  LEEDevents:LEEDEvent,
  logging:userlogging,
  logout:userlogout,
  creatingpitch:usercreatingpitch,
  experts:expertreducer,
  admins:adminsreducer,
  url:urlreducer
}
})
root.render(
  <>
  <BrowserRouter>
  <Provider store={store}>
      <App />
  </Provider>
  </BrowserRouter>
  </>
);

reportWebVitals();
