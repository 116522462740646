import React, { useState } from "react";
import leedimg from "../Assets/logo.png";
import Modal from "react-bootstrap/Modal";
import "../Views/ST.css";
import "../Views/ST.css";
import axios from "axios";
import {
  setexpertPitches,
  setexpertcomments,
  setexpertreports,
} from "../Controllers/redux";
import menupic from "../Assets/open-book.png";
import { Routes, Route, Link, Outlet, useNavigate } from "react-router-dom";
import sidebarcommentpic from "../Assets/chat.png";
import sidebarreportpic from "../Assets/list.png";
import Footer from "../components/footer";
import { useDispatch, useSelector } from "react-redux";
import helpdeskimg from "../Assets/help.png";
import {
  TbLayoutSidebarRightCollapseFilled,
  TbLayoutSidebarLeftCollapseFilled,
} from "react-icons/tb";
const cryptojs = require("crypto-js");
function STexpert() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const url = useSelector((state) => state.url.value);
  const [userName, setuserName] = React.useState("");
  const [Email, setEmail] = React.useState("");
  const [userProfilePic, setUserProfilePic] = React.useState("");
  const [userid, setuserid] = React.useState("");
  const [open, setopen] = React.useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(true); // Added sidebarVisible state

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  React.useEffect(() => {
    if (
      sessionStorage.getItem("email") !== null &&
      sessionStorage.getItem("email") !== undefined
    ) {
      var bytes = cryptojs.AES.decrypt(
        sessionStorage.getItem("name"),
        "kowshik123"
      );
      setuserName(() => bytes.toString(cryptojs.enc.Utf8));
      var bytesemail = cryptojs.AES.decrypt(
        sessionStorage.getItem("email"),
        "kowshik123"
      );
      setEmail(() => bytesemail.toString(cryptojs.enc.Utf8));
      var bytesimage = cryptojs.AES.decrypt(
        JSON.parse(sessionStorage.getItem("pic")),
        "kowshik123"
      );
      setUserProfilePic(() => bytesimage.toString(cryptojs.enc.Utf8));
      var bytesuserid = cryptojs.AES.decrypt(
        JSON.parse(sessionStorage.getItem("userid")),
        "kowshik123"
      );
      setuserid(() => bytesuserid.toString(cryptojs.enc.Utf8));
    }
  }, []);
  React.useEffect(() => {});
  React.useEffect(() => {
    if (Email) {
      axios
        .post(`${url}/expertpitchesST`, {
          email: Email,
        })
        .then((res) => {
          dispatch(
            setexpertPitches(
              JSON.parse(
                cryptojs.AES.decrypt(res.data.docs, "kowshik123").toString(
                  cryptojs.enc.Utf8
                )
              )
            )
          );
        });
      axios
        .post(`${url}/commentSTexpert`, {
          email: Email,
        })
        .then((res) =>
          dispatch(
            setexpertcomments(
              JSON.parse(
                cryptojs.AES.decrypt(res.data.docs, "kowshik123").toString(
                  cryptojs.enc.Utf8
                )
              )
            )
          )
        );
      axios
        .post(`${url}/reportsexpertST`, {
          email: Email,
        })
        .then((res) =>
          dispatch(
            setexpertreports(
              JSON.parse(
                cryptojs.AES.decrypt(res.data.docs, "kowshik123").toString(
                  cryptojs.enc.Utf8
                )
              )
            )
          )
        );
    }
  }, [Email]);
  return (
    <div className="">
      <div className="flex flex-row">
        <div className="w-screen z-1 lg:flex-row flex fixed py-10 lg:pt-10 pt-20 h-screen ">
          {/* <SidebarEvent choosepicker={choosepicker} helpdesk={helpdeskfunc} /> */}
          <button
            onClick={() => {
              setIsHidden(true);
            }}
            className="lg:hidden block absolute"
          >
            <TbLayoutSidebarRightCollapseFilled color="blue" size={50} />
          </button>
          {/* sidebar */}

          <div
            className={`w-3/4 z-1 min-h-screen fixed lg:hidden block top-0 bg-gradient-to-l from-blue-500 to-teal-500 ${
              isHidden == false ? "-left-full" : "left-0"
            }`}
          >
            <button
              onClick={() => {
                setIsHidden((prev) => !prev);
              }}
              className="text-4xl lg:hidden block pt-20"
            >
              <TbLayoutSidebarLeftCollapseFilled color="white" size={50} />
            </button>
            <div className="lg:pl-96 pl-10 justify-evenly">
              <div className="d-flex flex-column justify-content-between pr-10 bg-transparent">
                <div className="sidebar">
                  <br />
                  <img
                    src={userProfilePic}
                    style={{ borderRadius: "50%", width: 70, height: 70 }}
                    alt=""
                  />
                  <p className="text-white">{userName}</p>
                  <div className="bg-transparent" style={{ padding: 5 }}>
                    {" "}
                    <Link
                      style={{ textDecoration: "none", color: "white" }}
                      className="d-flex justify-content-evenly align-items-center"
                      to="comments"
                    >
                      YOUR COMMENTS{" "}
                      <img
                        src={sidebarcommentpic}
                        style={{
                          width: 30,
                          height: 30,
                          background: "white",
                          padding: 2,
                          borderRadius: "50%",
                        }}
                        alt=""
                      />{" "}
                    </Link>
                  </div>
                  <hr />
                  <div className="bg-transparent" style={{ padding: 5 }}>
                    {" "}
                    <Link
                      style={{ textDecoration: "none", color: "white" }}
                      className="d-flex justify-content-evenly align-items-center"
                      to="reports"
                    >
                      YOUR REPORTS{" "}
                      <img
                        src={sidebarreportpic}
                        style={{
                          width: 30,
                          height: 30,
                          background: "white",
                          padding: 2,
                          borderRadius: "50%",
                        }}
                        alt=""
                      />
                    </Link>
                  </div>
                  <hr />
                </div>
                  <div className="pt-48 text-center">
                  <div style={{ margin: "2%", cursor: "pointer" }}>
                    <a
                      className="text-white p-4 no-underline hover:text-white"
                      onClick={() => navigate("/contact")}
                    >
                      HELPDESK
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <Modal
                  style={{ textAlign: "center", fontFamily: "Inter" }}
                  show={open}
                  onHide={() => setopen(false)}
                  backdrop="static"
                  size="sm"
                  centered
                >
                  <Modal.Header closeButton>
                    <div className="w-100" style={{ textAlign: "center" }}>
                      <Modal.Title className="text-primary text-center">
                        <br />
                        <img
                          src={userProfilePic}
                          style={{
                            borderRadius: "50%",
                            marginTop: "0",
                            width: 70,
                            height: 70,
                          }}
                          alt=""
                        />
                        <p>{userName}</p>
                      </Modal.Title>
                    </div>
                  </Modal.Header>
                  <Modal.Body style={{ paddingBottom: 0 }}>
                    <div
                      onClick={() => setopen(false)}
                      style={{ boxShadow: " 0 0 10px rgba(3, 201, 169, 0.3)" }}
                      className=" border d-flex flex-column justify-content-between"
                    >
                      <div className="sidebar">
                        <div style={{ padding: 5 }}>
                          {" "}
                          <Link
                            style={{ textDecoration: "none", color: "white" }}
                            className="d-flex justify-content-evenly align-items-center"
                            to="comments"
                          >
                            YOUR COMMENTS{" "}
                            <img
                              src={sidebarcommentpic}
                              style={{
                                width: 30,
                                height: 30,
                                background: "white",
                                padding: 2,
                                borderRadius: "50%",
                              }}
                              alt=""
                            />{" "}
                          </Link>
                        </div>
                        <hr />
                        <div style={{ padding: 5 }}>
                          {" "}
                          <Link
                            style={{ textDecoration: "none", color: "white" }}
                            className="d-flex justify-content-evenly align-items-center"
                            to="reports"
                          >
                            YOUR REPORTS{" "}
                            <img
                              src={sidebarreportpic}
                              style={{
                                width: 30,
                                height: 30,
                                background: "white",
                                padding: 2,
                                borderRadius: "50%",
                              }}
                              alt=""
                            />
                          </Link>
                        </div>{" "}
                        <hr />
                        <div className="sidebar">
                          <div
                            style={{
                              background: "white",
                              margin: "2%",
                              cursor: "pointer",
                            }}
                          >
                            <a
                              className="text-white no-underline "
                              onClick={() => navigate("/contact")}
                            >
                              HELPDESK{" "}
                              <img
                                src={helpdeskimg}
                                style={{ width: 20, height: 20 }}
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                    <br />
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
          <div
            className={`w-1/5 z-1 min-h-screen relative justify-evenly lg:block hidden bg-gradient-to-l from-blue-500 to-teal-500`}
          >
            <button
              onClick={() => {
                setIsHidden((prev) => !prev);
              }}
              className="text-4xl lg:hidden block pt-20"
            >
              <TbLayoutSidebarLeftCollapseFilled color="white" size={50} />
            </button>
            <div className="pt-10 pl-10">
              <div className="d-flex flex-column justify-content-between pr-10 bg-transparent">
                <div className="sidebar">
                  <br />
                  <img
                    src={userProfilePic}
                    style={{ borderRadius: "50%", width: 70, height: 70 }}
                    alt=""
                  />
                  <p className="text-white">{userName}</p>
                  <div className="bg-transparent" style={{ padding: 5 }}>
                    {" "}
                    <Link
                      style={{ textDecoration: "none", color: "white" }}
                      className="d-flex justify-content-evenly align-items-center"
                      to="comments"
                    >
                      YOUR COMMENTS{" "}
                      <img
                        src={sidebarcommentpic}
                        style={{
                          width: 30,
                          height: 30,
                          background: "white",
                          padding: 2,
                          borderRadius: "50%",
                        }}
                        alt=""
                      />{" "}
                    </Link>
                  </div>
                  <hr />
                  <div className="bg-transparent" style={{ padding: 5 }}>
                    {" "}
                    <Link
                      style={{ textDecoration: "none", color: "white" }}
                      className="d-flex justify-content-evenly align-items-center"
                      to="reports"
                    >
                      YOUR REPORTS{" "}
                      <img
                        src={sidebarreportpic}
                        style={{
                          width: 30,
                          height: 30,
                          background: "white",
                          padding: 2,
                          borderRadius: "50%",
                        }}
                        alt=""
                      />
                    </Link>
                  </div>
                  <hr />
                </div>
                <div className="pt-80">
                  <div style={{ margin: "2%", cursor: "pointer" }}>
                    <a
                      className="text-white p-4 no-underline hover:text-white"
                      onClick={() => navigate("/contact")}
                    >
                      HELPDESK
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <Modal
                  style={{ textAlign: "center", fontFamily: "Inter" }}
                  show={open}
                  onHide={() => setopen(false)}
                  backdrop="static"
                  size="sm"
                  centered
                >
                  <Modal.Header closeButton>
                    <div className="w-100" style={{ textAlign: "center" }}>
                      <Modal.Title className="text-primary text-center">
                        <br />
                        <img
                          src={userProfilePic}
                          style={{
                            borderRadius: "50%",
                            marginTop: "0",
                            width: 70,
                            height: 70,
                          }}
                          alt=""
                        />
                        <p>{userName}</p>
                      </Modal.Title>
                    </div>
                  </Modal.Header>
                  <Modal.Body style={{ paddingBottom: 0 }}>
                    <div
                      onClick={() => setopen(false)}
                      style={{ boxShadow: " 0 0 10px rgba(3, 201, 169, 0.3)" }}
                      className=" border d-flex flex-column justify-content-between"
                    >
                      <div className="sidebar">
                        <div style={{ padding: 5 }}>
                          {" "}
                          <Link
                            style={{ textDecoration: "none", color: "white" }}
                            className="d-flex justify-content-evenly align-items-center"
                            to="comments"
                          >
                            YOUR COMMENTS{" "}
                            <img
                              src={sidebarcommentpic}
                              style={{
                                width: 30,
                                height: 30,
                                background: "white",
                                padding: 2,
                                borderRadius: "50%",
                              }}
                              alt=""
                            />{" "}
                          </Link>
                        </div>
                        <hr />
                        <div style={{ padding: 5 }}>
                          {" "}
                          <Link
                            style={{ textDecoration: "none", color: "white" }}
                            className="d-flex justify-content-evenly align-items-center"
                            to="reports"
                          >
                            YOUR REPORTS{" "}
                            <img
                              src={sidebarreportpic}
                              style={{
                                width: 30,
                                height: 30,
                                background: "white",
                                padding: 2,
                                borderRadius: "50%",
                              }}
                              alt=""
                            />
                          </Link>
                        </div>{" "}
                        <hr />
                        <div className="sidebar">
                          <div
                            style={{
                              background: "white",
                              margin: "2%",
                              cursor: "pointer",
                            }}
                          >
                            <a
                              className="link-success link-offset-2 link-underline-opacity-25"
                              onClick={() => navigate("/contact")}
                            >
                              HELPDESK{" "}
                              <img
                                src={helpdeskimg}
                                style={{ width: 20, height: 20 }}
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                    <br />
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:w-full w-screen justify-end h-fit pt-40 pl-80">
        <div id="SThead">
          <h1
            style={{
              fontWeight: 700,
              color: "black",
              paddingRight: "60px",
            }}
          >
            DIGITAL FLAGSHIP -
          </h1>
        </div>

        <div className="d-flex MainST">
          <div
            style={{
              flex: window.innerWidth < 800 ? 1 : 0.85,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
export default STexpert;
